





import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class PageNotFound extends Vue {
  created () {
    let userData = this.$store.state.userData
    if (userData) {
      if (userData.role === 'super-admin') {
        this.$router.push({ path: '/sadmin/dashboard' })
      } else if (userData.role === 'brand') {
        this.$router.push({ path: '/brand/dashboard' })
      }
    } else {
      this.$router.push({ path: '/brand/login' })
    }
  }
}
