import Staff from '@/container/Staff.vue'

export default {
  path: '/',
  component: Staff,
  redirect: '/staff/dashboard',
  children: [
    {
      path: '/staff/dashboard',
      name: 'staff-dashboard',
      component: () => import('@/views/SAdmin/Dashboard.vue'),
      meta: {
        name: 'staff-dashboard',
        requiresAuth: true,
        userType: 'staff',
        title: 'Staff Dashboard',
        breadcrumb: 'Login',
        sidebar: false
      }
    },
    {
      path: '/staff/picklrs',
      name: 'staff-picklrlist',
      component: () => import('@/views/SAdmin/Picklrs.vue'),
      meta: {
        requiresAuth: true,
        userType: 'staff',
        name: 'staff-picklrs',
        title: 'Picklr',
        breadcrumb: 'Picklrs'
      }
    },
    {
      path: '/staff/pickl-responses/:picklId',
      name: 'picklDetails',
      component: () => import('@/views/SAdmin/PicklResponses.vue'),
      meta: {
        requiresAuth: true,
        userType: 'staff',
        name: 'pickl-responses',
        title: 'Alerts',
        breadcrumb: 'Alerts'
      }
    },
    {
      path: '/staff/picklrs/:picklrId',
      name: 'staff-picklrdetails',
      component: () => import('@/views/SAdmin/PicklrDetails.vue'),
      meta: {
        requiresAuth: true,
        userType: 'staff',
        name: 'staff-picklr',
        title: 'Picklr',
        breadcrumb: 'Details'
      }
    },
    {
      path: '/staff/brands',
      name: 'brandlist',
      component: () => import('@/views/SAdmin/Brand.vue'),
      meta: {
        requiresAuth: true,
        userType: 'staff',
        name: 'staff-brands',
        title: 'Brand',
        breadcrumb: 'Brands'
      }
    },
    {
      path: '/staff/brands/:id',
      name: 'staff-branddetails',
      component: () => import('@/views/SAdmin/BrandDetails.vue'),
      meta: {
        requiresAuth: true,
        userType: 'staff',
        name: 'staff-brands',
        title: 'Brand',
        breadcrumb: 'Brand Details'
      }
    },
    {
      path: '/staff/pickls',
      name: 'staff-pickllist',
      component: () => import('@/views/SAdmin/Pickls.vue'),
      meta: {
        requiresAuth: true,
        userType: 'staff',
        name: 'pickls',
        title: 'Alerts',
        breadcrumb: 'Alerts'
      }
    },
    {
      path: '/staff/pickls/:picklId',
      name: 'pickldetails',
      component: () => import('@/views/SAdmin/PicklDetails.vue'),
      meta: {
        requiresAuth: true,
        userType: 'staff',
        name: 'pickls',
        title: 'Alerts',
        breadcrumb: 'Alerts'
      }
    },
    // {
    //   path: '/staff/store-locations',
    //   name: 'staff-store-locations',
    //   component: () => import('@/views/SAdmin/StoreLocations.vue'),
    //   meta: {
    //     requiresAuth: true,
    //     userType: 'staff',
    //     title: 'Stores Locations | Staff',
    //     breadcrumb: 'Stores',
    //     name: 'staff-store-location'
    //   }
    // },
    // {
    //   path: '/staff/stores',
    //   name: 'staff-stores',
    //   component: () => import('@/views/SAdmin/Stores.vue'),
    //   meta: {
    //     requiresAuth: true,
    //     userType: 'staff',
    //     title: 'Stores | Staff',
    //     breadcrumb: 'Stores',
    //     name: 'staff-store'
    //   }
    // },
    {
      path: '/staff/profile',
      name: 'staffprofile',
      component: () => import('@/views/SAdmin/Profile.vue'),
      meta: {
        requiresAuth: true,
        userType: 'staff',
        name: 'profile',
        title: 'View Profile',
        breadcrumb: 'View Profile'
      }
    },
    {
      path: '/staff/brand-subscription-history',
      name: 'brand-subscription-history-staff',
      component: () => import('@/views/SAdmin/BrandSubscriptionHistory.vue'),
      meta: {
        requiresAuth: true,
        userType: 'staff',
        name: 'BrandSubscriptionHistory',
        title: 'Brand Subscription History',
        breadcrumb: 'Brand Subscription History'
      }
    },
    {
      path: '/staff/brands/:brandId/create-pickl',
      name: 'staff-create-pickl',
      component: () => import('@/views/SAdmin/CreatePickl.vue'),
      meta: {
        name: 'staff-create-pickl',
        requiresAuth: true,
        userType: 'staff',
        title: 'Create Pickl',
        breadcrumb: 'Create Pickl'
      }
    },
    {
      path: '/staff/brands/:brandId/data-visuals',
      name: 'staff-brand-data-visuals',
      component: () => import('@/views/SAdmin/BrandReports.vue'),
      meta: {
        name: 'staff-brand-data-visuals',
        requiresAuth: true,
        userType: 'staff',
        title: 'Data Visuals',
        breadcrumb: 'Data Visuals'
      }
    },
    {
      path: '/staff/non-geo-pickls',
      name: 'staff-non-geo-pickllist',
      component: () => import('@/views/SAdmin/NonGeoPickls.vue'),
      meta: {
        requiresAuth: true,
        userType: 'staff',
        name: 'non-geo-pickls',
        title: 'Non Geo Alerts',
        breadcrumb: 'Non Geo Alerts'
      }
    },
    {
      path: '/staff/non-geo-pickl/:picklId',
      name: 'picklStats',
      component: () => import('@/views/SAdmin/NonGeoPicklDetails.vue'),
      meta: {
        requiresAuth: true,
        userType: 'staff',
        name: 'Go-Anywhere Pickl Stats',
        title: 'Go-Anywhere Pickl Stats',
        breadcrumb: 'Go-Anywhere Stats'
      }
    },
    {
      path: '/staff/brands/:brandId/create-non-geo-pickl',
      name: 'staff-create-non-geo-pickl',
      component: () => import('@/views/SAdmin/CreateNonGeoPickl.vue'),
      meta: {
        name: 'staff-create-non-geo-pickl',
        requiresAuth: true,
        userType: 'staff',
        title: 'Create Go-Anywhere Pickl',
        breadcrumb: 'Create Go-Anywhere Pickl'
      }
    },
    {
      path: '/staff/brands/:brandId/create-do-anywhere-pickl',
      name: 'staff-create-do-anywhere-pickl',
      component: () => import('@/views/SAdmin/CreateDoAnywherePickl.vue'),
      meta: {
        name: 'staff-create-do-anywhere-pickl',
        requiresAuth: true,
        userType: 'staff',
        title: 'Create Do Anywhere Pickl',
        breadcrumb: 'Create Do Anywhere Pickl'
      }
    },
    {
      path: '/staff/brands/:brandId/surveys/:surveyId/stats',
      name: 'staff-survey-stats',
      component: () => import('@/views/Brand/SurveyStats.vue'),
      meta: {
        name: 'staff-survey-stats',
        requiresAuth: true,
        userType: 'staff',
        title: 'Survey Stats',
        breadcrumb: 'Survey Stats'
      }
    }
  ]
}
