<template>
  <div
    :id="popupId"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2>
            <img src="/img/Alerts/plusbig_icon.png"> Store Location
          </h2>
          <button
            ref="popupCloseButton"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form v-on:submit.prevent="addStore" autocomplete="off" data-vv-scope="add-location">
            <h3>Select Store</h3>
            <select
              v-model="location.store_id"
              name="store_id"
              v-validate="'required'"
              :class="{'input': true, 'is-danger': errors.has('add-location.store_id') }"
            >
              <option value="" selected>Select Store</option>
              <option v-for="s in stores" :key="s.id" :value="s.id">{{s.name}}</option>
            </select>
            <h3>Select Country</h3>
            <select
              v-model="countryIso"
              name="country"
              v-validate="'required'"
              :class="{'input': true, 'is-danger': errors.has('add-location.country') }"
            >
              <option value="0" selected>Select Country</option>
              <option v-for="country in countries" :key="country.id" :value="country.iso_code">{{country.name}}</option>
            </select>
            <h3>Google Geo Address</h3>
            <vue-google-autocomplete
              ref="address"
              autocomplete="off"
              :id="'map-edit-' + editStoreFlag"
              placeholder="Address"
              v-on:placechanged="getAddressData"
              :country="[countryIso]"
              v-model="location.address"
              name="address"
              v-validate="'required'"
              :class="{'input': true, 'is-danger': errors.has('add-location.address') }"
              @change="clear"
            ></vue-google-autocomplete>
            <!-- <span class="error" v-show="errors.has('address')">{{ errors.first('address') }}</span> -->
            <h3>Filter Search Address</h3>
            <input
              type="text"
              name="city"
              placeholder="City"
              v-model="location.city"
              :class="{'input': true, 'is-danger': errors.has('add-location.city') }"
              v-validate="'required'"
            >
            <!-- <span class="error" v-show="errors.has('city')">{{ errors.first('city') }}</span> -->
            <input
              type="text"
              name="state"
              placeholder="State"
              v-model="location.state"
              :class="{'input': true, 'is-danger': errors.has('add-location.state') }"
              v-validate="'required'"
            >
            <!-- <span class="error" v-show="errors.has('state')">{{ errors.first('state') }}</span> -->
            <input
              type="text"
              name="zip_code"
              placeholder="Zipcode"
              v-model="location.zip_code"
              :class="{'input': true, 'is-danger': errors.has('add-location.zip_code') }"
              v-validate="'required'"
            >
            <!-- <span class="error" v-show="errors.has('zip_code')">{{ errors.first('zip_code') }}</span> -->
            <div class="row">
              <div class="col-xs-12 col-sm-6" v-if="editStoreFlag">
                <button
                  type="button"
                  class="btn-lg-grey"
                  data-toggle="modal"
                  data-target="#deletelocationpopup"
                  @click="getStoreLocation"
                >Delete STORE</button>
                <!-- <button class="btn-lg-grey" type="button" @click="deleteStore">Delete Store</button> -->
              </div>
              <div class="col-xs-12" :class="{ 'col-sm-6': editStoreFlag }">
                <button
                  type="submit"
                  class="btn-lg-green"
                  :disabled="submittingForm"
                >{{ editStoreFlag ? 'UPDATE' : 'ADD' }} STORE</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
export default {
  name: 'StorePopup',
  props: ['popupId', 'editStoreFlag', 'locationDetails', 'stores', 'closeStoreEditPopup'],
  data () {
    return {
      location: {
        name: '',
        city: '',
        state: '',
        zip_code: '',
        longitude: '',
        latitude: '',
        address: '',
        store_id: ''
      },
      address: '',
      submittingForm: false,
      currentImage: '',
      countries: [],
      countryIso: 'US'
    }
  },
  components: {
    VueGoogleAutocomplete
  },
  mounted () {
    this.getCountries()
  },
  methods: {
    getCountries () {
      this.$http
        .get('countries?service_enabled=true')
        .then(
          (response) => {
            this.countries = response.body.data
          },
          (response) => {
          }
        )
    },
    addStore () {
      this.$validator.validateAll('add-location').then((result) => {
        if (result) {
          this.submittingForm = true
          if (this.editStoreFlag) {
            this.$http
              .put('store_locations/' + this.location.id, this.location, {
                headers: {
                  'user-type': 'sadmin'
                }
              })
              .then(
                function (response) {
                  this.$notify({ type: 'success', text: 'Store location has been updated successfully!' })
                  this.$emit('refreshStoreLocationsList')
                  this.submittingForm = false
                  this.resetForm()
                },
                function (response) {
                  this.errorMessage = response.body.message
                  this.submittingForm = false
                }
              )
          } else {
            this.$http
              .post('store_locations', this.location, {
                headers: {
                  'user-type': 'sadmin'
                }
              })
              .then(
                function (response) {
                  this.$notify({ type: 'success', text: 'Store location has been added successfully!' })
                  this.$emit('refreshStoreLocationsList')
                  this.submittingForm = false
                  this.resetForm()
                },
                function (response) {
                  this.errorMessage = response.body.message
                  this.submittingForm = false
                }
              )
          }
        } else {
          if (this.errors.has('add-location.address')) {
            this.$notify({ type: 'error', text: 'Please select the correct address from the list' })
          }
        }
      })
    },

    getStoreLocation () {
      this.$emit('getLocationDetails', this.location)
    },
    resetForm () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.$refs.address.clear()
      this.$nextTick()
        .then(() => {
          this.getCountries()
          this.errors.clear('add-location')
        })
    },
    getAddressData (addressData, placeResultData, id) {
      this.address = placeResultData.formatted_address
      this.location.address = placeResultData.formatted_address
      this.location.city = addressData.locality
      for (let i = placeResultData.address_components.length - 1; i > 0; i--) {
        if (placeResultData.address_components[i].types[0] === 'administrative_area_level_1') {
          this.location.state = placeResultData.address_components[i].long_name
        }
      }
      this.location.zip_code = addressData.postal_code
      this.location.latitude = addressData.latitude
      this.location.longitude = addressData.longitude
    },
    generateImage () {
      let url = this.myCroppa.generateDataUrl()
      if (!url) {
        return
      }

      this.location.image = url
    },
    CropEnable () {
      this.location.image = ''
      this.imageSelected = true
    },
    cropDisable () {
      this.location.image = ''
      this.imageSelected = false
    },
    clear () {
      if (this.address !== this.location.address) this.location.address = ''
    }
  },
  watch: {
    locationDetails () {
      this.location = Object.assign({}, this.locationDetails)
      this.currentImage = this.location.image
      this.location.image = ''
      this.$refs.address.update(this.location.address)
    },
    closeStoreEditPopup () {
      if (this.closeStoreEditPopup) {
        this.$refs.popupCloseButton.click()
      }
    }
  }
}
</script>
<style scoped>
.btn-negative {
  font-size: 15px;
  font-weight: 400;
  margin-top: 0;
}

.image-upload {
  display: inline-block;
  width: auto;
  text-align: center;
}
select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url("/img/Create Pickl/selectproductDropdown_icon.png");
  background-color: white;
}

option {
  color: black;
  background-color: white;
}
</style>
