var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "two-step" }, [
    _c("h2", [_vm._v("Two-factor authentication")]),
    _vm._v("\n    Verification code has been sent to "),
    _vm._m(0),
    _vm._v(". Enter the code.\n    "),
    _c("label", {
      staticClass: "error-msg",
      domProps: { innerHTML: _vm._s(_vm.errorMessage) }
    }),
    _c(
      "form",
      {
        attrs: { action: "#" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c(
          "div",
          _vm._l(6, function(n) {
            return _c("input", {
              key: n,
              ref: "two-step",
              refInFor: true,
              attrs: { type: "tel", maxlength: "1", autofocus: 1 == n },
              on: {
                keydown: function($event) {
                  return _vm.handleKeyPress(n - 1, $event)
                }
              }
            })
          }),
          0
        ),
        _c("div", { staticClass: "resend-text" }, [
          _vm._v("Didn't get a verification code? "),
          _c(
            "a",
            {
              staticClass: "purple-link",
              attrs: { href: "javascript:;" },
              on: { click: _vm.sendVerificationCodeAgain }
            },
            [_vm._v("Send Again")]
          )
        ]),
        _c("input", {
          staticClass: "btn-lg-green",
          attrs: { disabled: _vm.busy, type: "submit", value: "CONTINUE" }
        })
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("u", [_c("strong", [_vm._v("mobile")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }