<template>
    <div :class="{'sidebar-collapse': isSidebarCollapsed, 'sidebar-open': !isSidebarCollapsed }">
        <Sidebar :isSidebarCollapsed="isSidebarCollapsed" v-on:sidebarCollapsed="isSidebarCollapsed = true" />
        <div class="top-header">
            <div class="container-head">
                <div class="collapse navbar-collapse profile-action" id="navbarSupportedContent" v-if="$store.state.userData">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-transform: none;">
                                Hey {{$store.state.userData.name}}! <strong style="font-size:25px;vertical-align: sub;">˅</strong>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="text-transform: none;color: #782B55;">
                                <router-link to="/brand/profile" class="dropdown-item">Personal Profile </router-link>
                                <router-link to="/brand/details" class="dropdown-item">Brand Profile </router-link>
                                <router-link to="/brand/payment-history?tab=subscription" class="dropdown-item">Billing </router-link>

                                <a class="dropdown-item" data-toggle="modal" data-target="#switch-brand-popup" href="javascript:;">Switch Brand</a>
                                <a class="dropdown-item" data-toggle="modal" data-target="#brandpopup">Setup New Brand</a>
                                <a class="dropdown-item" @click="logout">Logout</a>
                            </div>
                        </li>
                    </ul>
                </div>
                <a v-on:click="isSidebarCollapsed = !isSidebarCollapsed"><img class="hamburger" src="/img/Dashboard/Navigation_icon.png" /></a>
                <span v-if="routeName == 'brand-dashboard'">
                    HOME
                </span>
                <span v-if="routeName == 'brand-subscription'">
                    SUBSCRIPTION
                </span>
                <span v-if="routeName == 'brand-pickl-history'">
                    PICKL HISTORY
                    <button class="header-action" data-toggle="modal" data-target="#choose-pickl-type-popup"><span class="hidden-xs">CREATE NEW PICKL</span></button>
                    <button
                        type="button"
                        class="header-action"
                        data-target="#expired-pickls-popup"
                        data-toggle="modal"
                        >RE-LAUNCH EXPIRED PICKLS</button>
                </span>
                <span v-if="routeName == 'brand-non-geo-pickl-history'">
                    @HOME PICKL HISTORY
                    <button class="header-action" data-toggle="modal" data-target="#choose-pickl-type-popup"><span class="hidden-xs">CREATE NEW PICKL</span></button>
                </span>
                <span v-if="routeName == 'brand-payment-history'">
                    BILLING
                </span>
                <span v-if="routeName == 'brand-tutorial-videos'">
                    Tutorial Videos
                </span>
                 <span v-if="routeName == 'brand-create-pickl'">
                    CREATE PICKL
                </span>
                 <span v-if="routeName == 'brand-create-non-geo-pickl'">
                    CREATE @HOME PICKL
                </span>
                <span v-if="routeName == 'brand-products'">
                    <span>PRODUCTS</span>
                    <button class="header-action" data-toggle="modal" data-target="#add-product-popup"><span class="hidden-xs">PRODUCT</span></button>
                </span>
                <span v-if="routeName == 'brand-media'">
                    <span>MEDIA</span>
                    <button class="header-action" data-toggle="modal" data-target="#add-media-popup"><span class="hidden-xs">MEDIA</span></button>
                </span>
                <span v-if="routeName == 'brand-surveys'">
                    <span>Consumer Insights</span>
                    <button class="header-action" v-if="$store.state.settings.enable_survey_feature == 1 && ($store.state.userData.surveyFeature == 1)" @click="$router.push('/brand/insight/create')"><span class="hidden-xs">Create Insight</span></button>
                </span>
                <span v-if="routeName == 'brand-coupons'">
                    <span>Coupons</span>
                    <button class="header-action" v-if="$store.state.userData.couponFeature == 1" @click="$router.push('/brand/coupons/create')"><span class="hidden-xs">Create Coupon</span></button>
                </span>
                <span v-if="routeName == 'brand-coupon-create'">
                    <span>Create Coupon</span>
                </span>
                <span v-if="routeName == 'brand-coupon-edit'">
                    <span>Edit Coupon</span>
                </span>
                 <span v-if="routeName == 'brand-location-catalogues'">
                    <span>Store Clusters</span>
                    <button class="header-action" @click="$router.push('/brand/location-catalogues/create')"><span class="hidden-xs">Create Cluster</span></button>
                </span>
                <!-- <span v-if="routeName == 'brand-location-catalogues'">
                    <span>Store Clusters</span>
                    <button class="header-action" @click="$router.push('/brand/location-catalogues/create')"><span class="hidden-xs">Create Cluster</span></button>
                </span> -->
                <span v-if="routeName == 'brand-profile'">
                    LOGIN PROFILE
                </span>
                <span v-if="routeName == 'brand-survey-create'">
                    CREATE Insight
                </span>
                <span v-if="routeName == 'create-location-catalogue'">
                    CREATE Store Cluster
                </span>
                <span v-if="routeName == 'brand-create-brand'">
                    Create New Brand
                </span>
                <span v-if="routeName == 'edit-location-catalogue'">
                    Edit Store Cluster
                </span>
                <span v-if="routeName == 'brand-video'">
                    VID
                </span>
                <span v-if="routeName == 'brand-details'">
                    BRAND DETAILS
                </span>
                <span v-if="routeName == 'brand-users'">
                    BRAND MANAGERS
                    <button class="header-action"><span class="hidden-xs" data-toggle="modal" data-target="#brand-invitation-link-modal">Send Invitation Link</span></button>
                </span>
                <span v-if="routeName=='brand-offers'">
                    OFFERS
                </span>
                <span v-if="routeName=='brand-survey-stats'">
                    Insight Stats
                </span>
                <div v-if="$store.state.loginAsBrand" class="loginAsBrand">
                    You are logged in as Brand!
                </div>
            </div>
        </div>
        <div class="below-header">
            <div class="container-main">
                <div class="out-padd container-inner bg-pickl">
                    <div class="container-overlay" v-on:click="isSidebarCollapsed = true"></div>
                    <router-view/>
                </div>
            </div>
        </div>
        <SwitchBrandPopup :popupId="'switch-brand-popup'"/>
        <BrandPopup popupId="brandpopup" />
        <choose-pickl-type-popup :brandId="$store.state.userData.brandId" :popupId="'choose-pickl-type-popup'"/>
        <expired-pickl-popup v-if="routeName =='brand-pickl-history' "  :popupId="'expired-pickls-popup'" :brandId="$store.state.userData.brandId"/>

    </div>
</template>

<script>
import Sidebar from '@/components/Brand/Sidebar.vue'
import SwitchBrandPopup from '@/components/Brand/SwitchBrandPopup.vue'
import BrandPopup from '@/components/Brand/BrandPopup.vue'
import ChoosePicklTypePopup from '@/components/common/ChoosePicklTypePopup.vue'
import ExpiredPicklPopup from '@/components/SAdmin/ExpiredPicklsPopup.vue'

export default {
  name: 'Brand',
  data: function () {
    return {
      isSidebarCollapsed: false,
      routeName: 'brand-dashboard'
    }
  },
  components: {
    Sidebar,
    SwitchBrandPopup,
    BrandPopup,
    ChoosePicklTypePopup,
    ExpiredPicklPopup
  },
  watch: {
    $route: function (to, from) {
      this.$data.routeName = to.meta.name
    }
  },
  mounted () {
    this.$data.routeName = this.$router.currentRoute.meta.name
    if (window.innerWidth < 1096) {
      this.$data.isSidebarCollapsed = true
    }
    console.log('Mounted')
    this.getBrandSubscriptionDetails()
  },
  updated () {
    if (!this.$store.state.loginAsBrand) {
      let loginToken = this.$cookies.get('loginToken')
      if (loginToken === null) {
        this.$cookies.set('loginToken', Date.now(), '4h')
        this.$store.dispatch('addLoginLog', { brandId: this.$store.state.userData.brandId, userId: this.$store.state.userData.id })
          .then(
            (response) => {
            },
            (response) => {
            }
          )
      }
    }
  },
  methods: {
    getBrandSubscriptionDetails () {
      this.$store.dispatch('getBrandCurrentSubscription', this.$store.state.userData.brandId).then((response) => {
        this.brandSubscriptionDetails = response.response.body.data
        if (this.brandSubscriptionDetails.data_access_subscription_id === null && this.brandSubscriptionDetails.dashboard_data_access === 0) {
          if (!this.$store.state.loginAsBrand) {
            this.$notify({ type: 'error', text: 'You need to have data access subscription to access brand dashboard' })
            this.$router.push('/brand/data-access-subscription')
          }
        }
      }, response => {
      })
    },
    logout () {
      let sadmin = this.$store.state.loginAsBrand
      let brandId = this.$store.state.userData.brandId

      this.$store.dispatch('logoutAsBrand').then(() => {
        if (sadmin) {
          this.$router.push(`/sadmin/brands/${brandId}`)
        } else {
          this.$router.push('/brand/login')
        }
      })
    },

    getUserByToken () {
      this.$store.dispatch('getUserData', 'brand')
        .then(
          (response) => {
            this.$store.commit('setUserDataToLocalStorage', response.body)
          }
        )
    }
  }
}
</script>

<style lang="scss" scoped>
$header-height: 70px;

@mixin responsive_width($sidebar-width) {
    .top-header .container-head {
        width: calc(100% - #{$sidebar-width});
        left: $sidebar-width;
    }

    .container-main {
        padding-left: $sidebar-width;
    }

    .sidebar-collapse .container-head,
    .sidebar-collapse .container-main {
        width: 100%;
        left: 0px;
    }
}

.top-header {
    position: fixed;
    top: 0;
    left: 0;
    height: $header-height;
    width: 100%;
    float: left;
    z-index: 20;
    overflow: visible;
    box-shadow: 0 -60px 34px 50px rgba(0, 0, 0, 0.24);
}

.top-header .container-head {
    padding: 15px 10px 14px;
    background: white;
    color: #782b54;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid #11db6d;
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    float: left;
    position: fixed;
    top: 0;
    height: $header-height;
    overflow: visible;
}

.top-header .container-head .hamburger {
    width: 21px;
    height: 18px;
    margin: 0 5px 5px;
    cursor: pointer;
}

.below-header {
    float: left;
    width: 100%;
}

.container-main {
    padding-top: $header-height;
    float: left;
    width: 100%;
}

.container-inner {
    position: relative;
    overflow: hidden;
    min-height: calc(100vh - #{$header-height});
}

.container-overlay {
    width: 100%;
    position: absolute;
    z-index: 19;
    background: rgba(255,255,255,0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
}

.container-head,
.container-main {
    transition: 0.3s all ease;
}

.sidebar-collapse .container-main {
    padding-left: 0;
}

.container-head .header-action {
    border: 1px solid #12db6d;
    border-radius: 360px;
    box-shadow: 2px 2px 4px rgba(26, 26, 26, 0.03);
    height: 40px;
    padding: 12px 20px 12px 40px;
    background-image: url("/img/Alerts/plusbig_icon.png");
    background-repeat: no-repeat;
    background-size: 15px 15px;
    background-position: 20px center;
    background-color: white;
    color: black;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    float: right;
    margin-right: 20px;
}

.profile-action {
    border: 1px solid #12db6d;
    border-radius: 360px;
    box-shadow: 2px 2px 4px rgba(26, 26, 26, 0.03);
    height: 40px;
    padding: 12px 20px 12px 40px;
    background-image: url("/img/Dashboard/PicklrActive_icon.png");
    background-repeat: no-repeat;
    background-size: 15px 15px;
    background-position: 20px center;
    background-color: white;
    color: black;
    font-weight: 500;
    font-size: 15px;
    float: right;
    margin-right: 20px;
    padding:4px 13px 12px 40px;
    line-height: 26px;
}

.loginAsBrand {
    position: absolute;
    top: $header-height;
    right: 20px;
    border: 1px dashed red;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background: white;
    padding: 10px;
    z-index: 1049;
    color: black;
    font-weight: 400;
    font-size: 16px;
    text-transform: none;
    line-height: 18px;
    letter-spacing: 0;
}

@media only screen and (max-width: 1095px) {
    .sidebar-open .container-main {
        overflow: hidden;
    }

    .container-inner, .top-header .container-head {
        width: 100vw !important;
    }

    .sidebar-open .container-overlay {
        display: block;
    }

    .sidebar-open .container-main {
        height: 100vh;
    }
}

@media only screen and (min-width: 576px) {
    @include responsive_width(320px);
}

@media only screen and (max-width: 575px) {
    .container-head .header-action {
        width: 40px;
        padding: 20px;
        background-position: center center;
        overflow: hidden;
        margin-right: 0;
    }

    .profile-action {
        width: 40px;
        padding: 20px;
        background-position: center center;
        overflow: hidden;
        margin-right: 0;
        display: block;
    }

    .open>.dropdown-menu {
        display: block;
    }
    .top-header .container-head {
        font-size: 22px;
    }

    .loginAsBrand {
        right: 5px;
        padding: 4px 6px;
        font-size: 14px;
    }

    @include responsive_width(279px);
}
.dropdown-menu {
    z-index: 99999;
}
li.nav-item {
    list-style: none;
}

.navbar-nav {
    padding:5px;
}

.navbar-nav>li>a {
    text-decoration: none;
    color: #782B55;
    font-size: 20px;
}

.navbar-nav>li>a:hover {
    text-decoration: none;
    color: #782B55;

}
.dropdown-item {
    display: block;
    border: solid 1px gainsboro;
    padding: 6px;
    color:#782B55;
}
.dropdown-menu {
    margin-left: -30px;
    text-decoration: none;
    color: black;
    cursor: pointer;
    font-weight: bold;
    min-width: 170px;
    margin-top:7px !important;
    padding:0px;
}
.dropdown-item:hover {
    text-decoration: none;
    color: black;
}
</style>
