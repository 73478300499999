





























































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class ChoosePicklTypePopup extends Vue {
  @Prop() popupId!: string;
  @Prop() brandId!: number
  public picklUrl: string = ''
  public nonGeoPicklUrl: string = ''
  public doAnywherePicklUrl: string = ''

  created () {
    if (this.$store.state.userData.role === 'brand' || this.$store.state.userData.role === 'brand-manager' || this.$store.state.userData.role === 'brand-billing-manager') {
      this.picklUrl = '/brand/create-pickl'
      this.nonGeoPicklUrl = '/brand/create-non-geo-pickl'
      this.doAnywherePicklUrl = '/brand/create-do-anywhere-pickl'
    } else if (this.$store.state.userData.role === 'super-admin') {
      this.picklUrl = '/sadmin/brands/' + this.brandId + '/create-pickl'
      this.nonGeoPicklUrl = '/sadmin/brands/' + this.brandId + '/create-non-geo-pickl'
      this.doAnywherePicklUrl = '/sadmin/brands/' + this.brandId + '/create-do-anywhere-pickl'
    } else if (this.$store.state.userData.role === 'staff') {
      this.picklUrl = '/staff/brands/' + this.brandId + '/create-pickl'
      this.nonGeoPicklUrl = '/staff/brands/' + this.brandId + '/create-non-geo-pickl'
      this.doAnywherePicklUrl = '/staff/brands/' + this.brandId + '/create-do-anywhere-pickl'
    }
  }
  goPickl () {
    let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
    popupCloseButton.click()
    this.$router.push(this.picklUrl)
  }
  goNonGeoPickl () {
    let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
    popupCloseButton.click()
    this.$router.push(this.nonGeoPicklUrl)
  }

  goDoAnywherePickl () {
    let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
    popupCloseButton.click()
    this.$router.push(this.doAnywherePicklUrl)
  }
}

