var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c("h2", [_vm._v("Switch Brand")]),
            _c("h3", { staticStyle: { "text-align": "center" } }, [
              _vm._v(
                "Current Brand: " + _vm._s(_vm.$store.state.userData.brandName)
              )
            ]),
            _c("button", {
              ref: "popupCloseButton",
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close"
              }
            })
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "form",
                {
                  attrs: { "data-vv-scope": "switch-user-brand" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.switchUserBrand($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "col-xs-12" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.brandId,
                            expression: "brandId"
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        class: {
                          input: true,
                          "is-danger": _vm.errors.has(
                            "switch-user-brand.brandId"
                          )
                        },
                        attrs: { name: "brandId" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.brandId = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("Select Brand")
                        ]),
                        _vm._l(_vm.userBrands, function(userBrand) {
                          return _c(
                            "option",
                            {
                              key: userBrand.brand.id,
                              attrs: {
                                disabled:
                                  userBrand.brand.id ==
                                  _vm.$store.state.userData.brandId
                              },
                              domProps: { value: userBrand.brand.id }
                            },
                            [_vm._v(_vm._s(userBrand.brand.name))]
                          )
                        })
                      ],
                      2
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn-lg-green",
                        attrs: { type: "submit" }
                      },
                      [_vm._v("Switch")]
                    )
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }