var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "sidebar-collapse": _vm.isSidebarCollapsed,
        "sidebar-open": !_vm.isSidebarCollapsed
      }
    },
    [
      _c("Sidebar", {
        attrs: { isSidebarCollapsed: _vm.isSidebarCollapsed },
        on: {
          sidebarCollapsed: function($event) {
            _vm.isSidebarCollapsed = true
          }
        }
      }),
      _c("div", { staticClass: "top-header" }, [
        _c("div", { staticClass: "container-head" }, [
          _vm.$store.state.userData
            ? _c(
                "div",
                {
                  staticClass: "collapse navbar-collapse profile-action",
                  attrs: { id: "navbarSupportedContent" }
                },
                [
                  _c("ul", { staticClass: "navbar-nav mr-auto" }, [
                    _c("li", { staticClass: "nav-item dropdown" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link dropdown-toggle",
                          staticStyle: { "text-transform": "none" },
                          attrs: {
                            href: "#",
                            id: "navbarDropdown",
                            role: "button",
                            "data-toggle": "dropdown",
                            "aria-haspopup": "true",
                            "aria-expanded": "false"
                          }
                        },
                        [
                          _vm._v(
                            "\n                            Hey " +
                              _vm._s(_vm.$store.state.userData.name) +
                              "! "
                          ),
                          _c(
                            "strong",
                            {
                              staticStyle: {
                                "font-size": "25px",
                                "vertical-align": "sub"
                              }
                            },
                            [_vm._v("˅")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu",
                          staticStyle: {
                            "text-transform": "none",
                            color: "#782B55"
                          },
                          attrs: { "aria-labelledby": "navbarDropdown" }
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "dropdown-item",
                              attrs: { to: "/brand/profile" }
                            },
                            [_vm._v("Personal Profile ")]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "dropdown-item",
                              attrs: { to: "/brand/details" }
                            },
                            [_vm._v("Brand Profile ")]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "dropdown-item",
                              attrs: {
                                to: "/brand/payment-history?tab=subscription"
                              }
                            },
                            [_vm._v("Billing ")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: {
                                "data-toggle": "modal",
                                "data-target": "#switch-brand-popup",
                                href: "javascript:;"
                              }
                            },
                            [_vm._v("Switch Brand")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: {
                                "data-toggle": "modal",
                                "data-target": "#brandpopup"
                              }
                            },
                            [_vm._v("Setup New Brand")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              on: { click: _vm.logout }
                            },
                            [_vm._v("Logout")]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "a",
            {
              on: {
                click: function($event) {
                  _vm.isSidebarCollapsed = !_vm.isSidebarCollapsed
                }
              }
            },
            [
              _c("img", {
                staticClass: "hamburger",
                attrs: { src: "/img/Dashboard/Navigation_icon.png" }
              })
            ]
          ),
          _vm.routeName == "brand-dashboard"
            ? _c("span", [_vm._v("\n                HOME\n            ")])
            : _vm._e(),
          _vm.routeName == "brand-subscription"
            ? _c("span", [
                _vm._v("\n                SUBSCRIPTION\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "brand-pickl-history"
            ? _c("span", [
                _vm._v("\n                PICKL HISTORY\n                "),
                _vm._m(0),
                _c(
                  "button",
                  {
                    staticClass: "header-action",
                    attrs: {
                      type: "button",
                      "data-target": "#expired-pickls-popup",
                      "data-toggle": "modal"
                    }
                  },
                  [_vm._v("RE-LAUNCH EXPIRED PICKLS")]
                )
              ])
            : _vm._e(),
          _vm.routeName == "brand-non-geo-pickl-history"
            ? _c("span", [
                _vm._v(
                  "\n                @HOME PICKL HISTORY\n                "
                ),
                _vm._m(1)
              ])
            : _vm._e(),
          _vm.routeName == "brand-payment-history"
            ? _c("span", [_vm._v("\n                BILLING\n            ")])
            : _vm._e(),
          _vm.routeName == "brand-tutorial-videos"
            ? _c("span", [
                _vm._v("\n                Tutorial Videos\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "brand-create-pickl"
            ? _c("span", [
                _vm._v("\n                CREATE PICKL\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "brand-create-non-geo-pickl"
            ? _c("span", [
                _vm._v("\n                CREATE @HOME PICKL\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "brand-products"
            ? _c("span", [_c("span", [_vm._v("PRODUCTS")]), _vm._m(2)])
            : _vm._e(),
          _vm.routeName == "brand-media"
            ? _c("span", [_c("span", [_vm._v("MEDIA")]), _vm._m(3)])
            : _vm._e(),
          _vm.routeName == "brand-surveys"
            ? _c("span", [
                _c("span", [_vm._v("Consumer Insights")]),
                _vm.$store.state.settings.enable_survey_feature == 1 &&
                _vm.$store.state.userData.surveyFeature == 1
                  ? _c(
                      "button",
                      {
                        staticClass: "header-action",
                        on: {
                          click: function($event) {
                            return _vm.$router.push("/brand/insight/create")
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "hidden-xs" }, [
                          _vm._v("Create Insight")
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.routeName == "brand-coupons"
            ? _c("span", [
                _c("span", [_vm._v("Coupons")]),
                _vm.$store.state.userData.couponFeature == 1
                  ? _c(
                      "button",
                      {
                        staticClass: "header-action",
                        on: {
                          click: function($event) {
                            return _vm.$router.push("/brand/coupons/create")
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "hidden-xs" }, [
                          _vm._v("Create Coupon")
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.routeName == "brand-coupon-create"
            ? _c("span", [_c("span", [_vm._v("Create Coupon")])])
            : _vm._e(),
          _vm.routeName == "brand-coupon-edit"
            ? _c("span", [_c("span", [_vm._v("Edit Coupon")])])
            : _vm._e(),
          _vm.routeName == "brand-location-catalogues"
            ? _c("span", [
                _c("span", [_vm._v("Store Clusters")]),
                _c(
                  "button",
                  {
                    staticClass: "header-action",
                    on: {
                      click: function($event) {
                        return _vm.$router.push(
                          "/brand/location-catalogues/create"
                        )
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "hidden-xs" }, [
                      _vm._v("Create Cluster")
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm.routeName == "brand-profile"
            ? _c("span", [
                _vm._v("\n                LOGIN PROFILE\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "brand-survey-create"
            ? _c("span", [
                _vm._v("\n                CREATE Insight\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "create-location-catalogue"
            ? _c("span", [
                _vm._v("\n                CREATE Store Cluster\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "brand-create-brand"
            ? _c("span", [
                _vm._v("\n                Create New Brand\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "edit-location-catalogue"
            ? _c("span", [
                _vm._v("\n                Edit Store Cluster\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "brand-video"
            ? _c("span", [_vm._v("\n                VID\n            ")])
            : _vm._e(),
          _vm.routeName == "brand-details"
            ? _c("span", [
                _vm._v("\n                BRAND DETAILS\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "brand-users"
            ? _c("span", [
                _vm._v("\n                BRAND MANAGERS\n                "),
                _vm._m(4)
              ])
            : _vm._e(),
          _vm.routeName == "brand-offers"
            ? _c("span", [_vm._v("\n                OFFERS\n            ")])
            : _vm._e(),
          _vm.routeName == "brand-survey-stats"
            ? _c("span", [
                _vm._v("\n                Insight Stats\n            ")
              ])
            : _vm._e(),
          _vm.$store.state.loginAsBrand
            ? _c("div", { staticClass: "loginAsBrand" }, [
                _vm._v(
                  "\n                You are logged in as Brand!\n            "
                )
              ])
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "below-header" }, [
        _c("div", { staticClass: "container-main" }, [
          _c(
            "div",
            { staticClass: "out-padd container-inner bg-pickl" },
            [
              _c("div", {
                staticClass: "container-overlay",
                on: {
                  click: function($event) {
                    _vm.isSidebarCollapsed = true
                  }
                }
              }),
              _c("router-view")
            ],
            1
          )
        ])
      ]),
      _c("SwitchBrandPopup", { attrs: { popupId: "switch-brand-popup" } }),
      _c("BrandPopup", { attrs: { popupId: "brandpopup" } }),
      _c("choose-pickl-type-popup", {
        attrs: {
          brandId: _vm.$store.state.userData.brandId,
          popupId: "choose-pickl-type-popup"
        }
      }),
      _vm.routeName == "brand-pickl-history"
        ? _c("expired-pickl-popup", {
            attrs: {
              popupId: "expired-pickls-popup",
              brandId: _vm.$store.state.userData.brandId
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "header-action",
        attrs: {
          "data-toggle": "modal",
          "data-target": "#choose-pickl-type-popup"
        }
      },
      [_c("span", { staticClass: "hidden-xs" }, [_vm._v("CREATE NEW PICKL")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "header-action",
        attrs: {
          "data-toggle": "modal",
          "data-target": "#choose-pickl-type-popup"
        }
      },
      [_c("span", { staticClass: "hidden-xs" }, [_vm._v("CREATE NEW PICKL")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "header-action",
        attrs: { "data-toggle": "modal", "data-target": "#add-product-popup" }
      },
      [_c("span", { staticClass: "hidden-xs" }, [_vm._v("PRODUCT")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "header-action",
        attrs: { "data-toggle": "modal", "data-target": "#add-media-popup" }
      },
      [_c("span", { staticClass: "hidden-xs" }, [_vm._v("MEDIA")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "header-action" }, [
      _c(
        "span",
        {
          staticClass: "hidden-xs",
          attrs: {
            "data-toggle": "modal",
            "data-target": "#brand-invitation-link-modal"
          }
        },
        [_vm._v("Send Invitation Link")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }