var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "show-video-popup",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-label": "Close"
                }
              },
              [_vm._v("×")]
            ),
            _c("div", { staticClass: "vertical-center-transform" }, [
              _c("video", {
                ref: "videoElement",
                attrs: { src: _vm.url, controls: "" }
              })
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }