var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "sidebar-collapse": _vm.isSidebarCollapsed,
        "sidebar-open": !_vm.isSidebarCollapsed
      }
    },
    [
      _c("div", { staticClass: "top-header" }, [
        _vm._m(0),
        _c("div", { staticClass: "container-head" }, [
          _c(
            "a",
            {
              on: {
                click: function($event) {
                  _vm.isSidebarCollapsed = !_vm.isSidebarCollapsed
                }
              }
            },
            [
              _c("img", {
                staticClass: "hamburger",
                attrs: { src: "/img/Dashboard/Navigation_icon.png" }
              })
            ]
          ),
          _vm.routeName == "branddashboard"
            ? _c("span", [_vm._v("\n                HOME\n            ")])
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "below-header" }, [
        _c("div", { staticClass: "sidebar" }, [
          _c("div", { staticClass: "sidebar-inner" }, [
            _c("div", { staticClass: "sidebar-profile" }, [
              _c("img", {
                staticClass: "profile-photo-sidebar",
                attrs: { src: "" }
              }),
              _c(
                "div",
                { staticClass: "profile-details" },
                [
                  _c("h4", [_vm._v("John Smith")]),
                  _c(
                    "router-link",
                    {
                      staticClass: "grey-link",
                      attrs: { to: "/brand-profile" },
                      nativeOn: {
                        click: function($event) {
                          _vm.isSidebarCollapsed = true
                        }
                      }
                    },
                    [_vm._v("View Profile")]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "clear" })
            ]),
            _c("ul", [
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: { to: "/brand-dashboard" },
                      nativeOn: {
                        click: function($event) {
                          _vm.isSidebarCollapsed = true
                        }
                      }
                    },
                    [_vm._v("Home")]
                  )
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: { to: "/pickl-history" },
                      nativeOn: {
                        click: function($event) {
                          _vm.isSidebarCollapsed = true
                        }
                      }
                    },
                    [_vm._v("PICKL History")]
                  )
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: { to: "/subscription" },
                      nativeOn: {
                        click: function($event) {
                          _vm.isSidebarCollapsed = true
                        }
                      }
                    },
                    [_vm._v("Subscription")]
                  )
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: { to: "/payment-history" },
                      nativeOn: {
                        click: function($event) {
                          _vm.isSidebarCollapsed = true
                        }
                      }
                    },
                    [_vm._v("Payment History")]
                  )
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: { to: "/product" },
                      nativeOn: {
                        click: function($event) {
                          _vm.isSidebarCollapsed = true
                        }
                      }
                    },
                    [_vm._v("Product Management")]
                  )
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      attrs: { to: "/video" },
                      nativeOn: {
                        click: function($event) {
                          _vm.isSidebarCollapsed = true
                        }
                      }
                    },
                    [_vm._v("Video Explaining")]
                  )
                ],
                1
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "container-main" }, [
          _c(
            "div",
            { staticClass: "out-padd container-inner" },
            [
              _c("div", {
                staticClass: "container-overlay",
                on: {
                  click: function($event) {
                    _vm.isSidebarCollapsed = true
                  }
                }
              }),
              _c("router-view")
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sidebar text-center" }, [
      _c("img", {
        staticClass: "sidebar-logo",
        attrs: { src: "/img/pickl-logo.png" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }