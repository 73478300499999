var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { "sidebar-collapse": _vm.isSidebarCollapsed } }, [
    _c("div", { staticClass: "top-header" }, [
      _c(
        "div",
        { staticClass: "sidebar text-center" },
        [
          _c(
            "router-link",
            {
              attrs: { to: "/brand/dashboard" },
              nativeOn: {
                click: function($event) {
                  return _vm.$emit("sidebarCollapsed")
                }
              }
            },
            [
              _c("img", {
                staticClass: "sidebar-logo",
                attrs: { src: "/img/Dashboard/HeaderLogo.png" }
              })
            ]
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "below-header" }, [
      _c("div", { staticClass: "sidebar" }, [
        _c("div", { staticClass: "sidebar-inner sidebar-mobile" }, [
          _vm.$store.state.userData
            ? _c("div", { staticClass: "sidebar-profile" }, [
                _c("img", {
                  staticClass: "profile-photo-sidebar",
                  attrs: {
                    src:
                      _vm.$store.state.userData.image + "?fit=crop&w=150&h=150"
                  },
                  on: {
                    error: function($event) {
                      return _vm.$common.changeDefaultImage($event, "brand")
                    }
                  }
                }),
                _c("div", { staticClass: "profile-details" }, [
                  _c("h4", [
                    _vm._v(_vm._s(_vm.$store.state.userData.brandName))
                  ])
                ]),
                _c("div", { staticClass: "clear" })
              ])
            : _vm._e(),
          _c("ul", [
            _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "tasks-cls",
                    attrs: { to: "/brand/reports" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.$emit("sidebarCollapsed")
                      }
                    }
                  },
                  [_vm._v("Data Visuals")]
                )
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "pickl-cls",
                    attrs: { to: "/brand/campaigns" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.$emit("sidebarCollapsed")
                      }
                    }
                  },
                  [_vm._v("Campaigns")]
                )
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "pickl-cls",
                    attrs: { to: "/brand/campaigns-data" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.$emit("sidebarCollapsed")
                      }
                    }
                  },
                  [_vm._v("Campaign Data")]
                )
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "offers-cls",
                    attrs: { to: "/brand/tutorial-videos" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.$emit("sidebarCollapsed")
                      }
                    }
                  },
                  [_vm._v("Tutorials")]
                )
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "home-cls",
                    attrs: { to: "/brand/dashboard" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.$emit("sidebarCollapsed")
                      }
                    }
                  },
                  [_vm._v("Home")]
                )
              ],
              1
            ),
            _vm.$store.state.userData.nonGeoPicklFeature == 1
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "pickl-cls",
                        attrs: { to: "/brand/non-geo-pickl-history" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$emit("sidebarCollapsed")
                          }
                        }
                      },
                      [_vm._v("Go-Anywhere")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "pickl-cls",
                    attrs: { to: "/brand/pickl-history" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.$emit("sidebarCollapsed")
                      }
                    }
                  },
                  [_vm._v("Go-Anywhere")]
                )
              ],
              1
            ),
            _vm.$store.state.settings.enable_survey_feature == 1
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "surveys-cls",
                        attrs: { to: "/brand/insights" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$emit("sidebarCollapsed")
                          }
                        }
                      },
                      [_vm._v("Consumer Insights")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "products-cls",
                    attrs: { to: "/brand/products" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.$emit("sidebarCollapsed")
                      }
                    }
                  },
                  [_vm._v("Product Activities")]
                )
              ],
              1
            ),
            _vm.$store.state.userData.nonGeoPicklFeature == 1
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "home-cls",
                        attrs: { to: "/brand/media" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$emit("sidebarCollapsed")
                          }
                        }
                      },
                      [_vm._v("Media Activities")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.$store.state.userData.couponFeature == 1
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "offers-cls",
                        attrs: { to: "/brand/coupons" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$emit("sidebarCollapsed")
                          }
                        }
                      },
                      [_vm._v("Deal Drops")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.$store.state.settings.enable_location_catalogue_feature == 1
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "catalogues-cls",
                        attrs: { to: "/brand/location-catalogues" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$emit("sidebarCollapsed")
                          }
                        }
                      },
                      [_vm._v("Store Clusters")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "offers-cls",
                    attrs: { to: "/brand/offers" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.$emit("sidebarCollapsed")
                      }
                    }
                  },
                  [_vm._v("Promotions")]
                )
              ],
              1
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "sidebar-desktop" },
          [
            _c("VuePerfectScrollbar", { staticClass: "scroll-height" }, [
              _c("div", { staticClass: "sidebar-inner" }, [
                _vm.$store.state.userData
                  ? _c(
                      "div",
                      { staticClass: "sidebar-profile" },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/brand/dashboard" } },
                          [
                            _c("img", {
                              staticClass: "profile-photo-sidebar",
                              attrs: {
                                src:
                                  _vm.$store.state.userData.image ||
                                  _vm.$common.getDefaultImage("brand")
                              },
                              on: {
                                error: function($event) {
                                  return _vm.$common.getDefaultImage("brand")
                                }
                              }
                            }),
                            _c("div", { staticClass: "profile-details" }, [
                              _c("h4", [
                                _vm._v(
                                  _vm._s(_vm.$store.state.userData.brandName)
                                )
                              ])
                            ])
                          ]
                        ),
                        _c("div", { staticClass: "clear" })
                      ],
                      1
                    )
                  : _vm._e(),
                _c("ul", [
                  _c("li"),
                  _c("li", [
                    _c("strong", { staticStyle: { "font-size": "120%" } }, [
                      _vm._v(" Campaign Activities")
                    ])
                  ]),
                  _vm.$store.state.userData.nonGeoPicklFeature == 1
                    ? _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "pickl-cls",
                              attrs: { to: "/brand/non-geo-pickl-history" }
                            },
                            [_vm._v("Consumer Located")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "pickl-cls",
                          attrs: { to: "/brand/pickl-history" }
                        },
                        [_vm._v("Physical Location ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "pickl-cls",
                          attrs: { to: "/brand/tutorial-videos" }
                        },
                        [_vm._v("Tutorials")]
                      )
                    ],
                    1
                  ),
                  _c("li"),
                  _c("li", [
                    _c("strong", { staticStyle: { "font-size": "120%" } }, [
                      _vm._v(" Data")
                    ])
                  ]),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "offers-cls",
                          attrs: { to: "/brand/reports" }
                        },
                        [_vm._v("Visuals")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "offers-cls",
                          attrs: { to: "/brand/campaigns" }
                        },
                        [_vm._v("Campaigns")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "offers-cls",
                          attrs: { to: "/brand/campaigns-data" }
                        },
                        [_vm._v("Insights")]
                      )
                    ],
                    1
                  ),
                  _c("li"),
                  _c("li", [
                    _c("strong", { staticStyle: { "font-size": "120%" } }, [
                      _vm._v(" Build Campaigns")
                    ])
                  ]),
                  _vm.$store.state.userData.nonGeoPicklFeature == 1
                    ? _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "surveys-cls",
                              attrs: { to: "/brand/media" }
                            },
                            [_vm._v("Digital Media")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$store.state.settings.enable_survey_feature == 1
                    ? _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "surveys-cls",
                              attrs: { to: "/brand/insights" }
                            },
                            [_vm._v(" Consumer Questions")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "surveys-cls",
                          attrs: { to: "/brand/products" }
                        },
                        [_vm._v("Activities & Products")]
                      )
                    ],
                    1
                  ),
                  _vm.$store.state.userData.couponFeature == 1
                    ? _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "surveys-cls",
                              attrs: { to: "/brand/coupons" }
                            },
                            [_vm._v("Coupons")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$store.state.settings.enable_location_catalogue_feature ==
                  1
                    ? _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "catalogues-cls",
                              attrs: { to: "/brand/location-catalogues" }
                            },
                            [_vm._v("Location Clusters")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("li"),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "home-cls",
                          attrs: { to: "/brand/offers" }
                        },
                        [_vm._v("Your Rewards")]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "clearfix" })
                ])
              ])
            ])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }