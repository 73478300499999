<template>
    <div :id="popupId" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h2><img src="/img/Alerts/plusbig_icon.png" /> Brand</h2>
                    <button ref="popupCloseButton" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    </button>
                </div>
                <div class="modal-body">
                    <form v-on:submit.prevent="addBrand" data-vv-scope="add-brand">
                      <div class="row">
                        <div class="col-md-6">
                          <h3>Basic Brand Details</h3>
                          <input type="text" placeholder="Brand Name" name="name" v-model="brand.name" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('add-brand.name') }" />
                          <input type="text" placeholder="Tagline" name="tagline" v-model="brand.tagline" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('add-brand.tagline') }" />
                          <input type="email" placeholder="Email" name="email" v-model="brand.email" v-validate="'required|email'" :class="{'input': true, 'is-danger': errors.has('add-brand.email') }" />
                        </div>
                        <div class="col-md-6">
                          <h3>Add Brand Logo</h3>
                        <!-- <div class="upload-control">
                            <img class="logo-preview" src="" /><br />
                            <span class="btn-positive uploadButton">
                                Select Image
                                <input type="file" />
                            </span>
                        </div> -->
                          <div class="image-upload">
                            <croppa v-model="myCroppa"
                                placeholder="Choose a brand logo"
                                initial-size="contain"
                                :placeholder-font-size="16"
                                :show-remove-button="false"
                                @file-choose="CropEnable"
                                @image-remove="cropDisable"
                                @move="CropEnable"
                                @zoom="CropEnable"
                                @new-image-drawn="generateImage"
                            ></croppa>

                            <input type="hidden" name="image" v-model="brand.logo" v-validate="'required'" /><br />
                            <span class="error-msg" v-show="errors.has('add-brand.image')">{{ errors.first('add-brand.image') }}</span><br/>
                            <button v-if="brand.logo" class="btn-negative" type="button" @click="myCroppa.remove()">Remove</button>
                            <button v-else class="btn-positive uploadButton" type="button" @click="generateImage" :disabled="!imageSelected">Crop</button>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <h3>Add Contact Person Details</h3>
                        <input type="text" placeholder="Name" name="contact_name" v-model="brand.contact_person_name" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('add-brand.contact_name') }" />
                        <input type="email" placeholder="Email" name="contact_email" v-model="brand.contact_person_email" v-validate="'required|email'" :class="{'input': true, 'is-danger': errors.has('add-brand.contact_email') }" />
                        <the-mask mask="### ###-####" type="tel" autocomplete="mobile" placeholder="Mobile Number" v-model="brand.contact_person_mobile_number" name="mobile" v-validate="{ required: true, regex: /^\d{3} ?\d{3}-?\d{4}$/ }" :class="{'input': true, 'is-danger': errors.has('add-brand.mobile') }"></the-mask>
                      </div>
                      <div class="col-md-6">
                        <h3>Social Handle Links</h3>
                        <input type="text" placeholder="Facebook Link" name="facebook_link" v-model="brand.facebook_link" v-validate="{url: {require_protocol: true }}" :class="{'input': true, 'is-danger': errors.has('add-brand.facebook_link') }" />
                        <input type="text" placeholder="Instagram Link" name="instagram_link" v-model="brand.instagram_link" v-validate="{url: {require_protocol: true }}" :class="{'input': true, 'is-danger': errors.has('add-brand.instagram_link') }" />
                        <input type="text" placeholder="LinkedIn Link" name="linkedin_link" v-model="brand.linkedin_link" v-validate="{url: {require_protocol: true }}" :class="{'input': true, 'is-danger': errors.has('add-brand.linkedin_link') }" />
                        <input type="text" placeholder="Twitter Link" name="twitter_link" v-model="brand.twitter_link" v-validate="{url: {require_protocol: true }}" :class="{'input': true, 'is-danger': errors.has('add-brand.twitter_link') }" />
                      </div>

                       <input type="submit" class="btn-lg-green" value="ADD BRAND" :disabled="submittingForm" />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'
export default {
  name: 'BrandPopup',
  props: ['popupId'],
  components: {
    TheMask
  },
  data: function () {
    return {
      brand: {
        name: '',
        tagline: '',
        email: '',
        logo: '',
        contact_person_email: '',
        contact_person_name: '',
        contact_person_mobile_number: '',
        facebook_link: '',
        twitter_link: '',
        instagram_link: '',
        linkedin_link: '',
        created_by_brand: false
      },
      myCroppa: {},
      submittingForm: false,
      imageSelected: false
    }
  },
  methods: {
    addBrand () {
      this.$validator.validateAll('add-brand').then((result) => {
        if (result) {
          this.submittingForm = true
          if (this.$store.state.userData.role === 'brand') {
            this.brand.created_by_brand = true
          }
          this.$store.dispatch('addBrand', this.brand).then(() => {
            this.$refs.popupCloseButton.click()
            this.resetForm()
            this.$notify({ type: 'success', text: 'Brand has been added successfully' })
            window.location.reload()
          }, (response) => {
            for (var key in response.body) {
              this.$notify({ type: 'error', text: response.body[key][0] })
              let field = this.$validator.fields.find({ name: key, scope: 'add-brand' })

              if (field) {
                this.$validator.errors.add({
                  id: field.id,
                  field: key,
                  msg: response.body[key][0],
                  scope: 'add-brand'
                })

                field.setFlags({
                  invalid: true,
                  valid: false,
                  validated: true
                })
              }
            }
            this.errorMessage = response.body.message
            this.submittingForm = false
          })
        } else {
          if (this.errors.has('add-brand.password:min')) {
            this.$notify({ type: 'error', text: 'Password should be at least 6 letters' })
          } else if (this.errors.has('add-brand.contact_mobile:regex')) {
            this.$notify({ type: 'error', text: 'Please enter a valid mobile number' })
          } else {
            this.$notify({ type: 'error', text: 'Please fill out all the required fields' })
          }
        }
      })
    },
    generateImage () {
      let url = this.myCroppa.generateDataUrl()
      if (!url) {
        return
      }

      this.brand.logo = url
    },
    CropEnable () {
      this.brand.logo = ''
      this.imageSelected = true
    },
    cropDisable () {
      this.brand.logo = ''
      this.imageSelected = false
    },
    resetForm () {
      this.myCroppa.remove()
      let myCroppa = this.myCroppa
      Object.assign(this.$data, this.$options.data.apply(this))
      this.myCroppa = myCroppa
      this.errors.clear('add-brand')
    }
  }
}
</script>

<style scoped>
.btn-negative {
  font-size: 15px;
  font-weight: 400;
  margin-top: 0;
}

.image-upload {
  display: inline-block;
  width: auto;
  text-align: center;
}
</style>
