

































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

@Component({
  components: {
    VuePerfectScrollbar
  }
})
export default class Sidebar extends Vue {
  @Prop() isSidebarCollapsed: boolean = false;

  logout () {
    this.$store.dispatch('logout').then(() => {
      this.$router.push('/staff/login')
    }, reason => { })
  }
}
