








import { Component, Vue } from 'vue-property-decorator'
import Layout from '@/components/SAdmin/Layout.vue'
import BrandLayout from '@/components/Brand/BrandLayout.vue'
import Login from '@/views/SAdmin/Login.vue'
import FullscreenMessage from '@/components/common/FullscreenMessage.vue'

@Component({
  components: {
    Layout,
    BrandLayout,
    Login,
    FullscreenMessage
  }
})
export default class App extends Vue {
  isLoggedIn: boolean = false;
  brand: boolean = false;

  checkBackButton () {
  }
  mounted () {
    document.addEventListener('backbutton', this.checkBackButton, false)
  }
}
