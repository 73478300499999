import Auth from '@/container/Auth.vue'

export default {
  path: '/',
  component: Auth,
  redirect: '/brand/login',
  children: [
    {
      path: '/brand/login',
      name: 'brand-login',
      component: () => import('@/views/Brand/Login.vue'),
      meta: {
        requiresAuth: false,
        title: 'Login | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/reset/password',
      name: 'reset-password',
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        requiresAuth: false,
        title: 'Reset Password',
        breadcrumb: 'Reset Password'
      }
    },
    {
      path: '/referral/:code',
      name: 'referal',
      component: () => import('@/views/Referral.vue'),
      meta: {
        requiresAuth: false,
        title: 'Referal',
        breadcrumb: 'Referal'
      }
    },
    {
      path: '/brand/signup',
      name: 'brand-signup',
      component: () => import('@/views/Brand/Signup.vue'),
      meta: {
        requiresAuth: false,
        title: 'Signup | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/sadmin/login',
      name: 'sadmin-login',
      component: () => import('@/views/SAdmin/Login.vue'),
      meta: {
        requiresAuth: false,
        title: 'Login | Super Admin',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/staff/login',
      name: 'staff-login',
      component: () => import('@/views/Staff/Login.vue'),
      meta: {
        requiresAuth: false,
        title: 'Login | Staff',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/overview/:sharedCode',
      name: 'task-overview',
      component: () => import('@/views/PicklOverview.vue'),
      meta: {
        requiresAuth: false,
        title: 'Task Overview',
        breadcrumb: 'Task Overview'
      }
    },
    {
      path: '/unsubscribe/email',
      name: 'unsubscribe-email',
      component: () => import('@/views/UnsubscriberEmailPage.vue'),
      meta: {
        requiresAuth: false,
        title: 'Unsubscribe Email',
        breadcrumb: 'Unsubscribe Email'
      }
    },
    {
      path: '/account/remove',
      name: 'account-remove',
      component: () => import('@/views/RemoveAccountPage.vue'),
      meta: {
        requiresAuth: false,
        title: 'Remove account from PICKL',
        breadcrumb: 'Remove account from PICKL'
      }
    }
  ]
}
