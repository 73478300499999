var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "sidebar-collapse": _vm.isSidebarCollapsed,
        "sidebar-open": !_vm.isSidebarCollapsed
      }
    },
    [
      _c("Sidebar", {
        attrs: { isSidebarCollapsed: _vm.isSidebarCollapsed },
        on: {
          sidebarCollapsed: function($event) {
            _vm.isSidebarCollapsed = true
          }
        }
      }),
      _c("div", { staticClass: "top-header" }, [
        _c("div", { staticClass: "container-head" }, [
          _c(
            "a",
            {
              on: {
                click: function($event) {
                  _vm.isSidebarCollapsed = !_vm.isSidebarCollapsed
                }
              }
            },
            [
              _c("img", {
                staticClass: "hamburger",
                attrs: { src: "/img/Dashboard/Navigation_icon.png" }
              })
            ]
          ),
          _vm.routeName == "staff-dashboard"
            ? _c("span", [_vm._v("\n                DASHBOARD\n            ")])
            : _vm._e(),
          _vm.routeName == "staff-brands"
            ? _c("span", [
                _vm._v("\n                BRANDS\n                "),
                _c(
                  "button",
                  {
                    staticClass: "header-action",
                    attrs: {
                      "data-toggle": "modal",
                      "data-target": "#brandpopup"
                    }
                  },
                  [_vm._v("BRAND")]
                )
              ])
            : _vm._e(),
          _vm.routeName == "staff-store"
            ? _c("span", [
                _vm._v("\n                STORES\n                "),
                _c(
                  "button",
                  {
                    staticClass: "header-action",
                    attrs: {
                      "data-toggle": "modal",
                      "data-target": "#storepopup"
                    }
                  },
                  [_vm._v("STORE")]
                )
              ])
            : _vm._e(),
          _vm.routeName == "staff-store-location"
            ? _c("span", [
                _vm._v("\n                STORE LOCATIONS\n                "),
                _c(
                  "button",
                  {
                    staticClass: "header-action",
                    attrs: {
                      "data-toggle": "modal",
                      "data-target": "#storelocationpopup"
                    }
                  },
                  [_vm._v("STORE LOCATION")]
                )
              ])
            : _vm._e(),
          _vm.routeName == "staff-staff"
            ? _c("span", [
                _vm._v("\n                STAFF\n                "),
                _c(
                  "button",
                  {
                    staticClass: "header-action",
                    attrs: {
                      "data-toggle": "modal",
                      "data-target": "#staffpopup"
                    }
                  },
                  [_vm._v("STAFF")]
                )
              ])
            : _vm._e(),
          _vm.routeName == "staff-picklrs"
            ? _c("span", [_vm._v("\n                PICKLRS\n            ")])
            : _vm._e(),
          _vm.routeName == "staff-picklr"
            ? _c("span", [_vm._v("\n                PICKLR\n            ")])
            : _vm._e(),
          _vm.routeName == "pickls"
            ? _c("span", [_vm._v("\n                ALERTS\n            ")])
            : _vm._e(),
          _vm.routeName == "profile"
            ? _c("span", [
                _vm._v("\n                VIEW PROFILE\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "settings"
            ? _c("span", [_vm._v("\n                SETTINGS\n            ")])
            : _vm._e(),
          _vm.routeName == "packs"
            ? _c("span", [_vm._v("\n                PACKS\n            ")])
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "below-header" }, [
        _c("div", { staticClass: "container-main" }, [
          _c(
            "div",
            { staticClass: "out-padd container-inner bg-pickl" },
            [
              _c("div", {
                staticClass: "container-overlay",
                on: {
                  click: function($event) {
                    _vm.isSidebarCollapsed = true
                  }
                }
              }),
              _c("router-view")
            ],
            1
          )
        ])
      ]),
      _c("BrandPopup", { attrs: { popupId: "brandpopup" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }