var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "carousel-videos" }, [
      _c(
        "div",
        {
          staticClass: "carousel slide",
          attrs: { id: "carousel-videos", "data-ride": "carousel" }
        },
        [
          _c(
            "div",
            { staticClass: "carousel-inner", attrs: { role: "listbox" } },
            [
              _c("div", { staticClass: "item active" }, [
                _c("div", { staticClass: "frame" }, [
                  _c("img", {
                    staticClass: "img-responsive center-block",
                    attrs: { src: "/img/brand_new_feature.png" }
                  })
                ])
              ]),
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "frame" }, [
                  _c("img", {
                    staticClass: "img-responsive center-block",
                    attrs: { src: "/img/slider_img_3.png" }
                  })
                ])
              ])
            ]
          ),
          _c("ol", { staticClass: "carousel-indicators" }, [
            _c(
              "li",
              {
                staticClass: "active",
                attrs: {
                  "data-target": "#carousel-videos",
                  "data-slide-to": "0"
                }
              },
              [_c("div", { staticClass: "dot" })]
            ),
            _c(
              "li",
              {
                attrs: {
                  "data-target": "#carousel-videos",
                  "data-slide-to": "1"
                }
              },
              [_c("div", { staticClass: "dot" })]
            )
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }