import Vue from 'vue'
import VueResource from 'vue-resource'
import VeeValidate from 'vee-validate'
import Notifications from 'vue-notification'
import Viewer from 'v-viewer'
import App from './App.vue'
import router from '@/router'
import store from './store'
import Common from './properties'
import VueCookies from 'vue-cookies'
import moment from 'moment'

import './registerServiceWorker'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'viewerjs/dist/viewer.css'
import 'vue-croppa/dist/vue-croppa.css'
import '@/assets/css/styles.css'

const VueApexCharts: any = require('vue-apexcharts')
const Croppa: any = require('vue-croppa')

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

Vue.filter('lowercase', function (str:string) {
  return str.toLowerCase().trim()
})
Vue.filter('uppercase', function (str:string) {
  return str.toUpperCase().trim()
})

Vue.filter('formattedDate', function (str:string) {
  return str.split(' ').slice(0, 2).join(' ')
})

Vue.filter('formattedPhpDate', function (str:string, type:string = 'full') {
  str = str.replace(/-/g, '/')
  var d = new Date(str)
  let month = type === 'short' ? months[d.getMonth()].substring(0, 3) : months[d.getMonth()]
  return d.getDate() + ' ' + month
})

/* take php date format
   and outputs'29 Nov 2018' or '29 November 2018' */
Vue.filter('fullDate', function (str:string, short:boolean = true) {
  str = str.replace(/-/g, '/')
  var d = new Date(str)
  let month = short ? months[d.getMonth()].substring(0, 3) : months[d.getMonth()]
  return d.getDate() + ' ' + month + ' ' + d.getFullYear()
})

Vue.filter('humanReadableDate', function (date: Date) {
  return moment(String(date)).format('MMMM Do YYYY, h:mm:ss a')
})

Vue.filter('formattedPhpTime', function (str:string) {
  str = str.replace(/-/g, '/')
  var d = new Date(str)
  var ampm = 'AM'
  var hours: any = d.getHours()
  var minutes: any = d.getMinutes()

  if (hours >= 12) {
    ampm = 'PM'
    hours %= 12
  }
  if (hours === 0) {
    hours = 12
  }

  if (hours < 10) hours = '0' + hours
  if (minutes < 10) minutes = '0' + minutes

  return hours + ':' + minutes + ' ' + ampm
})

Vue.filter('monthYear', function (str: string, short: boolean = true) {
  str = str.replace(/-/g, '/')
  var d = new Date(str)
  var month = months[d.getMonth()]
  if (short) month = month.substring(0, 3)
  return month + ' ' + d.getFullYear()
})

Vue.filter('phone', function (phone: string) {
  if (!phone) return ''
  let start = phone.length - 10
  if (start < 0) start = 0
  phone = phone.substring(start, start + 10)
  return phone.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
})

Vue.config.productionTip = false
Vue.use(VeeValidate)
Vue.use(Croppa)
Vue.use(VueResource)
Vue.use(VueApexCharts)
Vue.use(Notifications)
Vue.use(Viewer)
Vue.use(Common)
Vue.use(VueCookies)

let s = store
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let userData = s.state.userData

    if (userData === null) {
      let redirectPath = ''
      let pathname = window.location.pathname
      console.log(pathname)
      let uri = window.location.href.split('?')
      let getVars:any = {}
      if (uri.length === 2) {
        let vars = uri[1].split('&')
        vars.forEach(function (v) {
          let tmp = v.split('=')
          if (tmp.length === 2) {
            getVars[tmp[0]] = tmp[1]
          }
        })
        redirectPath = '?redirect=' + pathname + '?' + encodeURIComponent(uri[1])
      } else {
        redirectPath = '?redirect=' + pathname
      }
      return next({
        path: '/brand/login' + redirectPath
      })
    }
  }
  if (to.matched.some(record => record.meta.userType)) {
    let userData = s.state.userData
    if (userData && to.meta.userType !== userData.role) {
      if (userData.role === 'brand') {
        return next({
          path: '/brand/dashboard'
        })
      } else if (userData.role === 'super-admin') {
        return next({
          path: '/sadmin/dashboard'
        })
      } else if (userData.role === 'staff') {
        return next({
          path: '/staff/dashboard'
        })
      }
    }
  } else if (s.state.userData && s.state.userData.role) {
    let pathname = window.location.pathname
    if (s.state.userData.role === 'brand' || s.state.userData.role === 'brand-manager' || s.state.userData.role === 'brand-billing-manager') {
      let redirectPath = ''
      let uri = window.location.href.split('?')
      if (uri.length === 2) {
        let vars = uri[1].split('&')
        vars.forEach(function (v) {
          let tmp = v.split('=')
          if (tmp.length === 2) {
            if (tmp[0] === 'refer') {
              return next({
                path: '/brand/profile'
              })
            }
          }
        })
      } else if (pathname.indexOf('/overview/') === 0) {
      } else {
        return next({
          path: '/brand/dashboard'
        })
      }
    } else if (pathname.indexOf('/overview/') === 0) {
    } else {
      return next({
        path: '/'
      })
    }
  }
  document.title = to.meta.title
  next()
})

declare module 'vue/types/vue' {
  // 3. Declare augmentation for Vue
  interface VueConstructor {
    http: {
      options: {
        // eslint-disable-next-line
        root: string
      }
      post: any,
      get: any,
      patch: any,
      put: any,
      delete: any,
      interceptors: any
      headers: {
        common: {
          Authorization: string
        }
      }
    },
    common: any,
  }
}
Vue.http.interceptors.push((request: any, next: any) => {
  request.headers.set('content-type', 'application/json')
  let accessToken = store.state.accessToken
  if (store.state.loginAsBrand) accessToken = store.state.brandAccessToken
  let urlSplit = request.url.split('/')
  if (urlSplit[2] !== 'ml.pickl.pro') {
    request.headers.set('Authorization', 'Bearer ' + accessToken)
  }
  if (store.state.userData !== null) {
    if (store.state.userData.role === 'brand' || store.state.userData.role === 'brand-manager' || store.state.userData.role === 'brand-billing-manager') {
      request.headers.set('user-type', 'brand')
    } else if (store.state.userData.role === 'super-admin') {
      request.headers.set('user-type', 'sadmin')
    } else if (store.state.userData.role === 'staff') {
      request.headers.set('user-type', 'staff')
    }
  }
  try {
    next()
  } catch (e) {
    console.error(e)
  }

  return (response: any) => {
    let title = response.statusText
    switch (response.status) {
      case 401:
        if (!response.url.endsWith('logout')) {
          var loginPath = 'sadmin'
          if (store.state.userData.role === 'staff') {
            loginPath = 'staff'
          } else if (store.state.userData.role === 'brand') {
            loginPath = 'brand'
          } else {
            loginPath = 'sadmin'
          }
          store.dispatch('logoutAsBrand').catch(() => store.dispatch('resetLocalStorage').finally(() => router.push('/' + loginPath + '/login')))
        }
        Vue.notify({ text: 'You are not authorized to do that!', title: title, type: 'error' })
        break
      case 0:
        Vue.notify({ text: 'Unable to reach server', type: 'error' })
        break
      case 500:
        Vue.notify({ text: 'An internal server error has occurred', type: 'error' })
        break
      case 403:
        Vue.notify({ text: 'You are forbidden to do that', type: 'error' })
        break
      case 405:
        Vue.notify({ text: 'You are not allowed to do that', type: 'error' })
        break
      case 429:
        Vue.notify({ text: 'Please wait a few minutes before trying that again', type: 'error' })
        break
    }
  }
})

function autosize (el: HTMLElement, size: number, min: number) {
  el.style.fontSize = size + 'px'
  while ((el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight) && --size >= min) {
    el.style.fontSize = size + 'px'
  }
}

Vue.directive('autosize', {
  inserted: function (el, binding) {
    let target = binding.value.target || binding.value
    let min = binding.value.min || 11
    var resizeId: number
    window.addEventListener('resize', () => {
      clearTimeout(resizeId)
      resizeId = setTimeout(() => autosize(el, target, min), 500)
    })
    autosize(el, target, min)
  },
  componentUpdated: function (el, binding) {
    let target = binding.value.target || binding.value
    let min = binding.value.min || 11
    autosize(el, target, min)
  }
})

Vue.directive('squareImg', {
  inserted: function (element, binding) {
    let size = '100%' // max size on any axis
    let ratio = 1 // ratio of size y/x or height/width
    if (binding && binding.value) {
      if (binding.value.size) size = binding.value.size
      if (binding.value.ratio) ratio = binding.value.ratio
    }
    let el = element as HTMLImageElement
    el.style.maxHeight = 'auto'
    el.style.maxWidth = 'auto'
    el.addEventListener('load', () => {
      if (el.naturalWidth * ratio > el.naturalHeight) {
        if (size !== 'none') el.style.height = size
        el.style.width = 'auto'
        el.style.left = (el.offsetHeight / ratio - el.offsetWidth) / 2 + 'px'
      } else {
        if (size !== 'none') el.style.width = size
        el.style.height = 'auto'
        el.style.top = (el.offsetWidth * ratio - el.offsetHeight) / 2 + 'px'
        el.style.transform = 'none'
      }
    })
  }
})

Vue.http.options.root = process.env.VUE_APP_API_BASE_URL
// Vue.config.errorHandler = (err:any, vm, info) => {
//   try {
//     vm.$destroy()
//     Vue.notify({ text: 'An internal client error has occurred...', type: 'error' })
//   } finally {
//     let error = JSON.stringify(err, Object.getOwnPropertyNames(err))
//     store.dispatch('saveClientLog', { error: error, info: info }).catch(reason => console.error(reason))
//   }
// }

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
