<template>
    <div :class="{'sidebar-collapse': isSidebarCollapsed, 'sidebar-open': !isSidebarCollapsed }">
        <div class="top-header">
            <div class="sidebar text-center">
                <img class="sidebar-logo" src="/img/pickl-logo.png" />
            </div>
            <div class="container-head">
                <a v-on:click="isSidebarCollapsed = !isSidebarCollapsed"><img class="hamburger" src="/img/Dashboard/Navigation_icon.png" /></a>
                <span v-if="routeName == 'branddashboard'">
                    HOME
                </span>
            </div>
        </div>
        <div class="below-header">
            <div class="sidebar">
                <div class="sidebar-inner">
                    <div class="sidebar-profile">
                        <img class="profile-photo-sidebar" src="" />
                        <div class="profile-details">
                            <h4>John Smith</h4>
                            <router-link to="/brand-profile" v-on:click.native="isSidebarCollapsed = true" class="grey-link">View Profile</router-link>
                        </div>
                    <div class="clear"></div>
                    </div>
                    <ul>
                        <li><router-link to="/brand-dashboard" v-on:click.native="isSidebarCollapsed = true">Home</router-link></li>
                        <li><router-link to="/pickl-history" v-on:click.native="isSidebarCollapsed = true">PICKL History</router-link></li>
                        <li><router-link to="/subscription" v-on:click.native="isSidebarCollapsed = true">Subscription</router-link></li>
                        <li><router-link to="/payment-history" v-on:click.native="isSidebarCollapsed = true">Payment History</router-link></li>
                        <li><router-link to="/product" v-on:click.native="isSidebarCollapsed = true">Product Management</router-link></li>
                        <li><router-link to="/video" v-on:click.native="isSidebarCollapsed = true">Video Explaining</router-link></li>
                    </ul>
                </div>
            </div>
            <div class="container-main">
                <div class="out-padd container-inner">
                    <div class="container-overlay" v-on:click="isSidebarCollapsed = true"></div>
                    <router-view/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Layout',
  data: function () {
    return {
      isSidebarCollapsed: true,
      routeName: 'branddashboard'
    }
  },
  watch: {
    $route: function (to, from) {
      this.$data.routeName = to.name
    }
  },
  mounted () {
    this.$data.routeName = this.$router.currentRoute.name
  }
}
</script>

<style lang="scss" scoped>
$sidebar-width: 335px;
$header-height: 70px;

.top-header {
    position: fixed;
    top: 0;
    left: 0;
    height: $header-height;
    width: 100%;
    float: left;
    z-index: 20;
    overflow: hidden;
}

/* .top-header .sidebar {
    padding: 12px 10px;
} */

.top-header .container-head {
    padding: 15px 10px 14px;
    background: white;
    color: #782b54;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid #11db6d;
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    float: left;
    width: calc(100% - #{$sidebar-width});
    position: fixed;
    top: 0;
    left: $sidebar-width;
    height: $header-height;
    overflow: hidden;
}

.top-header .container-head .hamburger {
    width: 17px;
    height: 14px;
    margin: 0 5px 5px;
    cursor: pointer;
}

.below-header {
    float: left;
    width: 100%;
}

/* .below-header .sidebar-static {
    width: $sidebar-width;
    position: static;
    content: '';
    padding-top: $header-height;
    float: left;
} */

.below-header .sidebar {
    position: fixed;
    top: $header-height;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
}

.sidebar {
    background: #782b55;
    color: white;
    width: $sidebar-width;
    float: left;
    overflow: hidden;
    left: 0;
    position: fixed;
}

.sidebar-logo {
    width: 46px;
    height: 46px;
    margin: 12px 10px;
}

.container-main {
    padding-top: $header-height;
    float: left;
    width: 100%;
    padding-left: $sidebar-width;
}

.container-inner {
    position: relative;
    overflow: hidden;
    min-height: calc(100vh - #{$header-height});
}

.container-overlay {
    width: 100%;
    position: absolute;
    z-index: 19;
    background: rgba(255,255,255,0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
}

.sidebar-inner {
    padding: 15px 10px;
}

.sidebar-inner .sidebar-profile {
    float: left;
    width: 100%;
    margin-bottom: 15px;
}

.sidebar-inner .sidebar-profile img {
    float: left;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid rgb(233,233,233);
    margin-right: 10px;
}

.sidebar-inner .sidebar-profile .profile-details {
    float: left;
    width: 160px;
}

.sidebar-inner .sidebar-profile h4 {
    float: left;
    width: 100%;
    font-weight: 500;
    font-size: 22px;
    margin: 5px 0 0;
}

.sidebar-inner .sidebar-profile a {
    float: left;
    width: 100%;
    color: rgba(255,255,255,0.8);
    font-size: 17px;
}

.sidebar-inner ul {
    padding: 0;
}

.sidebar-inner ul li {
    float: left;
    width: 100%;
    margin-top: 15px;
    list-style-type: none;
}

.sidebar-inner ul li a {
    background-size: 25px 25px;
    background-position: 25px center;
    background-repeat: no-repeat;
    display: block;
    padding: 10px 25px 10px 65px;
    color: white;
    font-size: 23px;
    line-height: 23px;
    height: 43px;
    border-radius: 360px;
    text-decoration: none;
}

.sidebar-inner ul li a:hover,
.sidebar-inner ul li a.router-link-active {
    background-color: white;
    color: #782b54;
}

.sidebar,
.container-head,
.container-main {
    transition: 0.3s all ease;
}

.sidebar-collapse .sidebar {
    left: -$sidebar-width;
}

.sidebar-collapse .container-head,
.sidebar-collapse .container-main {
    width: 100%;
    left: 0px;
}

.sidebar-collapse .container-main {
    padding-left: 0;
}

.container-head .header-action {
    border: 1px solid #12db6d;
    border-radius: 360px;
    box-shadow: 2px 2px 4px rgba(26, 26, 26, 0.03);
    height: 40px;
    padding: 12px 20px 12px 40px;
    background-image: url("/img/Alerts/plusbig_icon.png");
    background-repeat: no-repeat;
    background-size: 15px 15px;
    background-position: 20px center;
    background-color: white;
    color: black;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    float: right;
    margin-right: 20px;
}

@media only screen and (max-width: 1095px) {
    .sidebar-open .container-main {
        overflow: hidden;
    }

    .container-inner, .top-header .container-head {
        width: 100vw;
    }
}

@media only screen and (max-width: 575px) {
    .sidebar-open .container-overlay {
        display: block;
    }

    .sidebar-open .container-main {
        height: 100vh;
    }

    .container-head .header-action {
        margin-right: 0;
    }
}
</style>
