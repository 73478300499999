















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

declare var $: any

@Component
export default class ShowVideoPopup extends Vue {
  @Prop() url!: string;

  mounted () {
    $('#show-video-popup').on('hidden.bs.modal', () =>
      (this.$refs.videoElement as HTMLVideoElement).pause()
    )
    $('#show-video-popup').on('shown.bs.modal', () =>
      (this.$refs.videoElement as HTMLVideoElement).play()
    )
  }
}
