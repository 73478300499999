<template>
    <div :id="popupId" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h2><img src="/img/Alerts/plusbig_icon.png" /> Brand</h2>
                    <button ref="popupCloseButton" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    </button>
                </div>
                <div class="modal-body">
                    <form v-on:submit.prevent="addBrand" data-vv-scope="add-brand" autocomplete="false">
                      <div class="row">
                        <div class="col-md-6">
                          <h3>Basic Brand Details</h3>
                          <input type="text" placeholder="Brand Name" name="name" v-model="brand.name" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('add-brand.name') }" />
                          <input type="text" placeholder="Tagline" name="tagline" v-model="brand.tagline" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('add-brand.tagline') }" />
                          <input type="email" placeholder="Email" name="email" autocomplete="false" v-model="brand.email" v-validate="'required|email'" :class="{'input': true, 'is-danger': errors.has('add-brand.email') }" />
                          <input type="password" placeholder="Password" autocomplete="new-password" name="password" v-model="brand.password" v-validate="{ required: true, min: 8, regex: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#\$%\^&\*])(?=.{8,})' }" :class="{'input': true, 'is-danger': errors.has('add-brand.password') }" />
                          <div class="focus-tip" title="At least 8 characters, with at least 1 upper case, 1 lower case and 1 special character / number."></div>
                        </div>
                        <div class="col-md-6">
                          <h3>Add Brand Logo</h3>
                          <div class="croppa-tip"></div>
                          <!-- <div class="upload-control">
                              <img class="logo-preview" src="" /><br />
                              <span class="btn-positive uploadButton">
                                  Select Image
                                  <input type="file" />
                              </span>
                          </div> -->
                          <div class="upload-remove-container">
                            <croppa v-model="myCroppa"
                                placeholder="Choose a brand logo"
                                initial-size="contain"
                                :placeholder-font-size="16"
                                :show-remove-button="false"
                            ></croppa>

                            <button v-if="myCroppa.hasImage && myCroppa.hasImage()" class="upload-remove" type="button" @click="myCroppa.remove()"></button>
                            <input type="hidden" name="image" v-model="brand.logo" v-validate="'required'" /><br />
                            <span class="error-msg" v-show="!(myCroppa.hasImage && myCroppa.hasImage()) && errors.has('add-brand.image')">{{ errors.first('add-brand.image') }}</span><br/>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <h3>Add Contact Person Details</h3>
                        <input type="text" placeholder="Name" name="contact_name" v-model="brand.contact_person_name" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('add-brand.contact_name') }" />
                        <input type="email" placeholder="Email" name="contact_email" v-model="brand.contact_person_email" v-validate="'required|email'" :class="{'input': true, 'is-danger': errors.has('add-brand.contact_email') }" />
                        <!-- <input type="tel" placeholder="Mobile" name="contact_mobile" v-model="brand.contact_person_mobile_number" v-validate="{ required: true, regex: /^[+][0-9]{3}[0-9]{6,10}$/ }" :class="{'input': true, 'is-danger': errors.has('add-brand.contact_mobile') }" /> -->
                        <the-mask mask="### ###-####" type="tel" autocomplete="mobile" placeholder="Mobile Number" v-model="brand.contact_person_mobile_number" name="mobile" v-validate="{ required: true, regex: /^\d{3} ?\d{3}-?\d{4}$/ }" :class="{'input': true, 'is-danger': errors.has('add-brand.mobile') }"></the-mask>
                      </div>
                      <div class="col-md-6">
                        <h3>Social Handle Links</h3>
                        <input type="text" placeholder="Facebook Link" name="facebook_link" v-model="brand.facebook_link" v-validate="{url: {require_protocol: true }}" :class="{'input': true, 'is-danger': errors.has('add-brand.facebook_link') }" />
                        <input type="text" placeholder="Instagram Link" name="instagram_link" v-model="brand.instagram_link" v-validate="{url: {require_protocol: true }}" :class="{'input': true, 'is-danger': errors.has('add-brand.instagram_link') }" />
                        <input type="text" placeholder="LinkedIn Link" name="linkedin_link" v-model="brand.linkedin_link" v-validate="{url: {require_protocol: true }}" :class="{'input': true, 'is-danger': errors.has('add-brand.linkedin_link') }" />
                        <input type="text" placeholder="Twitter Link" name="twitter_link" v-model="brand.twitter_link" v-validate="{url: {require_protocol: true }}" :class="{'input': true, 'is-danger': errors.has('add-brand.twitter_link') }" />
                      </div>
                      <div class="col-sm-12">
                          <h3 class="social-heading">Social Media Hashtags</h3>
                          <div class="">
                            <input type="text" placeholder="Hashtags" name="hashtags" v-model="brand.hashtags" />
                          </div>
                       </div>
                      <input type="submit" class="btn-lg-green" value="ADD BRAND" :disabled="submittingForm" />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'

export default {
  name: 'BrandPopup',
  props: ['popupId'],
  components: {
    TheMask
  },
  data: function () {
    return {
      brand: {
        name: '',
        tagline: '',
        email: '',
        password: '',
        logo: '',
        contact_person_email: '',
        contact_person_name: '',
        contact_person_mobile_number: '',
        facebook_link: '',
        instagram_link: '',
        linkedin_link: '',
        twitter_link: ''
      },
      myCroppa: {},
      submittingForm: false,
      imageSelected: false
    }
  },
  methods: {
    addBrand () {
      this.generateImage()
      this.$validator.validateAll('add-brand').then((result) => {
        if (result) {
          this.submittingForm = true
          this.$store.dispatch('addBrand', this.brand).then(() => {
            this.$store.dispatch('getBrandsList')
            this.$refs.popupCloseButton.click()
            this.resetForm()
            this.$notify({ type: 'success', text: 'Brand has been added successfully' })
            this.submittingForm = false
          }, (response) => {
            for (var key in response.body) {
              this.$notify({ type: 'error', text: response.body[key][0] })
              let field = this.$validator.fields.find({ name: key, scope: 'add-brand' })

              if (field) {
                this.$validator.errors.add({
                  id: field.id,
                  field: key,
                  msg: response.body[key][0],
                  scope: 'add-brand'
                })

                field.setFlags({
                  invalid: true,
                  valid: false,
                  validated: true
                })
              }
            }
            this.errorMessage = response.body.message
            this.submittingForm = false
          })
        } else {
          if (this.errors.has('add-brand.password:min')) {
            this.$notify({ type: 'error', text: 'Password should be at least 6 letters' })
          } else if (this.$validator.errors.has('add-brand.password:regex')) {
            this.$notify({ text: 'Password should contain at least one upper case, lower case and special characters', type: 'error' })
          } else if (this.errors.has('add-brand.contact_mobile:regex')) {
            this.$notify({ type: 'error', text: 'Please enter a valid mobile number' })
          } else {
            this.$notify({ type: 'error', text: 'Please fill out all the required fields' })
          }
        }
      })
    },
    generateImage () {
      let url = this.myCroppa.generateDataUrl()
      if (!url) {
        return
      }

      this.brand.logo = url
    },
    CropEnable () {
      this.brand.logo = ''
      this.imageSelected = true
    },
    cropDisable () {
      this.brand.logo = ''
      this.imageSelected = false
    },
    resetForm () {
      this.myCroppa.remove()
      let myCroppa = this.myCroppa
      Object.assign(this.$data, this.$options.data.apply(this))
      this.myCroppa = myCroppa
      this.$nextTick()
        .then(() => {
          this.errors.clear('add-brand')
        })
    }
  }
}
</script>

<style scoped>
.btn-negative {
  font-size: 15px;
  font-weight: 400;
  margin-top: 0;
}

.image-upload {
  display: inline-block;
  width: auto;
  text-align: center;
}
</style>
