







import Vue from 'vue'
import ShowVideoPopup from '@/components/common/ShowVideoPopup.vue'
import Component from 'vue-class-component'

@Component({
  components: {
    ShowVideoPopup
  }
})
export default class Auth extends Vue {
  public demoVideo: string =
    process.env.VUE_APP_ASSETS_URL + '/assets/videos/intro_video.mp4'
}
