var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("notifications", {
        staticClass: "notification-style",
        attrs: { position: "top center", width: "100%", max: 2 }
      }),
      _c("router-view"),
      _c("FullscreenMessage")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }