var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-lg" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._m(0),
            _c("button", {
              ref: "popupCloseButton",
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close"
              }
            })
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "form",
              {
                attrs: { "data-vv-scope": "add-brand", autocomplete: "false" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.addBrand($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("h3", [_vm._v("Basic Brand Details")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.brand.name,
                          expression: "brand.name"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has("add-brand.name")
                      },
                      attrs: {
                        type: "text",
                        placeholder: "Brand Name",
                        name: "name"
                      },
                      domProps: { value: _vm.brand.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.brand, "name", $event.target.value)
                        }
                      }
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.brand.tagline,
                          expression: "brand.tagline"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has("add-brand.tagline")
                      },
                      attrs: {
                        type: "text",
                        placeholder: "Tagline",
                        name: "tagline"
                      },
                      domProps: { value: _vm.brand.tagline },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.brand, "tagline", $event.target.value)
                        }
                      }
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.brand.email,
                          expression: "brand.email"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|email",
                          expression: "'required|email'"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has("add-brand.email")
                      },
                      attrs: {
                        type: "email",
                        placeholder: "Email",
                        name: "email",
                        autocomplete: "false"
                      },
                      domProps: { value: _vm.brand.email },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.brand, "email", $event.target.value)
                        }
                      }
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.brand.password,
                          expression: "brand.password"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            required: true,
                            min: 8,
                            regex:
                              "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*])(?=.{8,})"
                          },
                          expression:
                            "{ required: true, min: 8, regex: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#\\$%\\^&\\*])(?=.{8,})' }"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has("add-brand.password")
                      },
                      attrs: {
                        type: "password",
                        placeholder: "Password",
                        autocomplete: "new-password",
                        name: "password"
                      },
                      domProps: { value: _vm.brand.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.brand, "password", $event.target.value)
                        }
                      }
                    }),
                    _c("div", {
                      staticClass: "focus-tip",
                      attrs: {
                        title:
                          "At least 8 characters, with at least 1 upper case, 1 lower case and 1 special character / number."
                      }
                    })
                  ]),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("h3", [_vm._v("Add Brand Logo")]),
                    _c("div", { staticClass: "croppa-tip" }),
                    _c(
                      "div",
                      { staticClass: "upload-remove-container" },
                      [
                        _c("croppa", {
                          attrs: {
                            placeholder: "Choose a brand logo",
                            "initial-size": "contain",
                            "placeholder-font-size": 16,
                            "show-remove-button": false
                          },
                          model: {
                            value: _vm.myCroppa,
                            callback: function($$v) {
                              _vm.myCroppa = $$v
                            },
                            expression: "myCroppa"
                          }
                        }),
                        _vm.myCroppa.hasImage && _vm.myCroppa.hasImage()
                          ? _c("button", {
                              staticClass: "upload-remove",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.myCroppa.remove()
                                }
                              }
                            })
                          : _vm._e(),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.brand.logo,
                              expression: "brand.logo"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          attrs: { type: "hidden", name: "image" },
                          domProps: { value: _vm.brand.logo },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.brand, "logo", $event.target.value)
                            }
                          }
                        }),
                        _c("br"),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !(
                                    _vm.myCroppa.hasImage &&
                                    _vm.myCroppa.hasImage()
                                  ) && _vm.errors.has("add-brand.image"),
                                expression:
                                  "!(myCroppa.hasImage && myCroppa.hasImage()) && errors.has('add-brand.image')"
                              }
                            ],
                            staticClass: "error-msg"
                          },
                          [_vm._v(_vm._s(_vm.errors.first("add-brand.image")))]
                        ),
                        _c("br")
                      ],
                      1
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c("h3", [_vm._v("Add Contact Person Details")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.brand.contact_person_name,
                          expression: "brand.contact_person_name"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has("add-brand.contact_name")
                      },
                      attrs: {
                        type: "text",
                        placeholder: "Name",
                        name: "contact_name"
                      },
                      domProps: { value: _vm.brand.contact_person_name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.brand,
                            "contact_person_name",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.brand.contact_person_email,
                          expression: "brand.contact_person_email"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|email",
                          expression: "'required|email'"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has("add-brand.contact_email")
                      },
                      attrs: {
                        type: "email",
                        placeholder: "Email",
                        name: "contact_email"
                      },
                      domProps: { value: _vm.brand.contact_person_email },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.brand,
                            "contact_person_email",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c("the-mask", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            required: true,
                            regex: /^\d{3} ?\d{3}-?\d{4}$/
                          },
                          expression:
                            "{ required: true, regex: /^\\d{3} ?\\d{3}-?\\d{4}$/ }"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has("add-brand.mobile")
                      },
                      attrs: {
                        mask: "### ###-####",
                        type: "tel",
                        autocomplete: "mobile",
                        placeholder: "Mobile Number",
                        name: "mobile"
                      },
                      model: {
                        value: _vm.brand.contact_person_mobile_number,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.brand,
                            "contact_person_mobile_number",
                            $$v
                          )
                        },
                        expression: "brand.contact_person_mobile_number"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("h3", [_vm._v("Social Handle Links")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.brand.facebook_link,
                        expression: "brand.facebook_link"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { url: { require_protocol: true } },
                        expression: "{url: {require_protocol: true }}"
                      }
                    ],
                    class: {
                      input: true,
                      "is-danger": _vm.errors.has("add-brand.facebook_link")
                    },
                    attrs: {
                      type: "text",
                      placeholder: "Facebook Link",
                      name: "facebook_link"
                    },
                    domProps: { value: _vm.brand.facebook_link },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.brand,
                          "facebook_link",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.brand.instagram_link,
                        expression: "brand.instagram_link"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { url: { require_protocol: true } },
                        expression: "{url: {require_protocol: true }}"
                      }
                    ],
                    class: {
                      input: true,
                      "is-danger": _vm.errors.has("add-brand.instagram_link")
                    },
                    attrs: {
                      type: "text",
                      placeholder: "Instagram Link",
                      name: "instagram_link"
                    },
                    domProps: { value: _vm.brand.instagram_link },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.brand,
                          "instagram_link",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.brand.linkedin_link,
                        expression: "brand.linkedin_link"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { url: { require_protocol: true } },
                        expression: "{url: {require_protocol: true }}"
                      }
                    ],
                    class: {
                      input: true,
                      "is-danger": _vm.errors.has("add-brand.linkedin_link")
                    },
                    attrs: {
                      type: "text",
                      placeholder: "LinkedIn Link",
                      name: "linkedin_link"
                    },
                    domProps: { value: _vm.brand.linkedin_link },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.brand,
                          "linkedin_link",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.brand.twitter_link,
                        expression: "brand.twitter_link"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { url: { require_protocol: true } },
                        expression: "{url: {require_protocol: true }}"
                      }
                    ],
                    class: {
                      input: true,
                      "is-danger": _vm.errors.has("add-brand.twitter_link")
                    },
                    attrs: {
                      type: "text",
                      placeholder: "Twitter Link",
                      name: "twitter_link"
                    },
                    domProps: { value: _vm.brand.twitter_link },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.brand, "twitter_link", $event.target.value)
                      }
                    }
                  })
                ]),
                _c("div", { staticClass: "col-sm-12" }, [
                  _c("h3", { staticClass: "social-heading" }, [
                    _vm._v("Social Media Hashtags")
                  ]),
                  _c("div", {}, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.brand.hashtags,
                          expression: "brand.hashtags"
                        }
                      ],
                      attrs: {
                        type: "text",
                        placeholder: "Hashtags",
                        name: "hashtags"
                      },
                      domProps: { value: _vm.brand.hashtags },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.brand, "hashtags", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _c("input", {
                  staticClass: "btn-lg-green",
                  attrs: {
                    type: "submit",
                    value: "ADD BRAND",
                    disabled: _vm.submittingForm
                  }
                })
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("img", { attrs: { src: "/img/Alerts/plusbig_icon.png" } }),
      _vm._v(" Brand")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }