
























































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

@Component({
  components: {
    VuePerfectScrollbar
  }
})
export default class Sidebar extends Vue {
    @Prop() isSidebarCollapsed: boolean = false;
    created () {
      this.$store.dispatch('getAppSettingsKeyValuePair')
    }
}
