var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "label",
      {
        staticStyle: {
          "text-align": "justify",
          "font-size": "18px",
          color: "black"
        }
      },
      [_vm._v("Hey! This is the BRAND LOGIN page.")]
    ),
    _c("br"),
    _c(
      "label",
      {
        staticStyle: {
          "text-align": "justify",
          "font-size": "18px",
          color: "black",
          "margin-top": "10px"
        }
      },
      [_vm._v("Download PICKL at the Apple/Google App store")]
    ),
    _c("label", {
      staticClass: "error-msg",
      domProps: { innerHTML: _vm._s(_vm.errorMessage) }
    }),
    _c(
      "form",
      {
        attrs: { action: "#" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _vm.brandRefer
          ? _c("h4", { staticStyle: { "text-align": "center" } }, [
              _vm._v("You have been invited by "),
              _c("strong", { staticClass: "green-text" }, [
                _vm._v(_vm._s(_vm.accessInvitaion.brand.name))
              ]),
              _vm._v(" to join pickl")
            ])
          : _vm._e(),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.user.email,
              expression: "user.email"
            }
          ],
          attrs: {
            type: "email",
            placeholder: "Email",
            autocomplete: "email",
            required: "",
            disabled: _vm.brandRefer
          },
          domProps: { value: _vm.user.email },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.user, "email", $event.target.value)
            }
          }
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.user.password,
              expression: "user.password"
            }
          ],
          attrs: {
            type: "password",
            placeholder: "Password",
            autocomplete: "current-password",
            required: ""
          },
          domProps: { value: _vm.user.password },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.user, "password", $event.target.value)
            }
          }
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.user.remember,
              expression: "user.remember"
            }
          ],
          attrs: { type: "checkbox", id: "remember" },
          domProps: {
            checked: Array.isArray(_vm.user.remember)
              ? _vm._i(_vm.user.remember, null) > -1
              : _vm.user.remember
          },
          on: {
            change: function($event) {
              var $$a = _vm.user.remember,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.user, "remember", $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.user,
                      "remember",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.user, "remember", $$c)
              }
            }
          }
        }),
        _c("label", { attrs: { for: "remember" } }, [_vm._v("Remember me")]),
        _vm.brandRefer
          ? _c("input", {
              staticClass: "btn-lg-green",
              attrs: {
                disabled: _vm.busy,
                type: "submit",
                value: "ACCEPT INVITATION!"
              }
            })
          : _c("input", {
              staticClass: "btn-lg-green",
              attrs: {
                disabled: _vm.busy,
                type: "submit",
                value: "GOOOOO PICKL!!"
              }
            })
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _vm._v("Welcome,"),
      _c("br"),
      _vm._v("\n    It’s "),
      _c("span", { staticClass: "green-text" }, [_vm._v("PICKL")]),
      _vm._v(" Time")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }