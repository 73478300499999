<template>
    <div :class="{'sidebar-collapse': isSidebarCollapsed, 'sidebar-open': !isSidebarCollapsed }">
        <Sidebar :isSidebarCollapsed="isSidebarCollapsed" v-on:sidebarCollapsed="isSidebarCollapsed = true" />
        <div class="top-header">
            <div class="container-head">
                <a v-on:click="isSidebarCollapsed = !isSidebarCollapsed"><img class="hamburger" src="/img/Dashboard/Navigation_icon.png" /></a>
                <span v-if="routeName == 'staff-dashboard'">
                    DASHBOARD
                </span>
                <span v-if="routeName == 'staff-brands'">
                    BRANDS
                    <button class="header-action" data-toggle="modal" data-target="#brandpopup">BRAND</button>
                </span>
                 <span v-if="routeName == 'staff-store'">
                    STORES
                    <button class="header-action" data-toggle="modal" data-target="#storepopup">STORE</button>
                </span>
                <span v-if="routeName == 'staff-store-location'">
                    STORE LOCATIONS
                    <button class="header-action" data-toggle="modal" data-target="#storelocationpopup">STORE LOCATION</button>
                </span>
                <span v-if="routeName == 'staff-staff'">
                    STAFF
                    <button class="header-action" data-toggle="modal" data-target="#staffpopup">STAFF</button>
                </span>
                <span v-if="routeName == 'staff-picklrs'">
                    PICKLRS
                </span>
                  <span v-if="routeName == 'staff-picklr'">
                    PICKLR
                </span>
                <span v-if="routeName == 'pickls'">
                    ALERTS
                </span>
                <span v-if="routeName == 'profile'">
                    VIEW PROFILE
                </span>
                <span v-if="routeName == 'settings'">
                    SETTINGS
                </span>
                <span v-if="routeName == 'packs'">
                    PACKS
                </span>
            </div>
        </div>
        <div class="below-header">
            <div class="container-main">
                <div class="out-padd container-inner bg-pickl">
                    <div class="container-overlay" v-on:click="isSidebarCollapsed = true"></div>
                    <router-view/>
                </div>
            </div>
        </div>
        <!-- popups -->
        <BrandPopup popupId="brandpopup" />
    </div>
</template>

<script>
import BrandPopup from '@/components/SAdmin/BrandPopup.vue'
import Sidebar from '@/components/Staff/Sidebar.vue'

export default {
  name: 'Layout',
  data: function () {
    return {
      isSidebarCollapsed: false,
      routeName: 'staff-dashboard'
    }
  },
  components: {
    BrandPopup,
    Sidebar
  },
  watch: {
    $route: function (to, from) {
      this.$data.routeName = to.meta.name
    }
  },
  mounted () {
    this.$data.routeName = this.$router.currentRoute.meta.name
    if (window.innerWidth < 1096) {
      this.$data.isSidebarCollapsed = true
    }
  }
}
</script>

<style lang="scss" scoped>
$sidebar-width: 280px;
$header-height: 70px;

.top-header {
    position: fixed;
    top: 0;
    left: 0;
    height: $header-height;
    width: 100%;
    float: left;
    z-index: 20;
    overflow: hidden;
    box-shadow: 0 -60px 34px 50px rgba(0, 0, 0, 0.24);
}

.top-header .container-head {
    padding: 15px 10px 14px;
    background: white;
    color: #782b54;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid #11db6d;
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    float: left;
    width: calc(100% - #{$sidebar-width});
    position: fixed;
    top: 0;
    left: $sidebar-width;
    height: $header-height;
    overflow: hidden;
}

.top-header .container-head .hamburger {
    width: 21px;
    height: 18px;
    margin: 0 5px 5px;
    cursor: pointer;
}

.below-header {
    float: left;
    width: 100%;
}

.container-main {
    padding-top: $header-height;
    float: left;
    width: 100%;
    padding-left: $sidebar-width;
}

.container-inner {
    position: relative;
    overflow: hidden;
    min-height: calc(100vh - #{$header-height});
}

.container-overlay {
    width: 100%;
    position: absolute;
    z-index: 19;
    background: rgba(255,255,255,0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
}

.container-head,
.container-main {
    transition: 0.3s all ease;
}

.sidebar-collapse .container-head,
.sidebar-collapse .container-main {
    width: 100%;
    left: 0px;
}

.sidebar-collapse .container-main {
    padding-left: 0;
}

.container-head .header-action {
    border: 1px solid #12db6d;
    border-radius: 360px;
    box-shadow: 2px 2px 4px rgba(26, 26, 26, 0.03);
    height: 40px;
    padding: 12px 20px 12px 40px;
    background-image: url("/img/Alerts/plusbig_icon.png");
    background-repeat: no-repeat;
    background-size: 15px 15px;
    background-position: 20px center;
    background-color: white;
    color: black;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    float: right;
    margin-right: 20px;
}

@media only screen and (max-width: 1095px) {
    .sidebar-open .container-main {
        overflow: hidden;
    }

    .container-inner, .top-header .container-head {
        width: 100vw;
    }

    .sidebar-open .container-overlay {
        display: block;
    }

    .sidebar-open .container-main {
        height: 100vh;
    }
}

@media only screen and (max-width: 575px) {
    .container-head .header-action {
        margin-right: 0;
    }

    .top-header .container-head {
        font-size: 22px;
    }
}
</style>
