var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.state.showMessage
    ? _c("div", { staticClass: "overlay" }, [
        _c("div", { ref: "outer", staticClass: "outer" }, [
          _c("img", {
            staticClass: "img-responsive",
            attrs: { src: "/img/pickl-logo.png" }
          }),
          _c("br"),
          _c("div", {
            staticClass: "msg",
            domProps: { innerHTML: _vm._s(_vm.$store.state.message) }
          })
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }