<template>
    <div>
        <h2>Welcome,<br />
        It’s <span class="green-text">PICKL</span> Time</h2>
        <label style="text-align:justify;font-size:18px;color:black;">Hey! This is the BRAND LOGIN page.</label><br/>
        <label style="text-align:justify;font-size:18px;color:black;margin-top:10px;">Download PICKL at the Apple/Google App store</label>
        <label class="error-msg" v-html="errorMessage"></label>
        <form action="#" v-on:submit.prevent="onSubmit">
            <h4 v-if="brandRefer" style="text-align:center;" >You have been invited by <strong class="green-text">{{accessInvitaion.brand.name}}</strong> to join pickl</h4>
            <input type="email" v-model="user.email" placeholder="Email" autocomplete="email" required :disabled="brandRefer"/>
            <input type="password" v-model="user.password" placeholder="Password" autocomplete="current-password" required />
            <input type="checkbox" v-model="user.remember" id="remember" /> <label for="remember">Remember me</label>
            <input v-if="brandRefer" :disabled="busy" type="submit" value="ACCEPT INVITATION!" class="btn-lg-green" />
            <input v-else :disabled="busy" type="submit" value="GOOOOO PICKL!!" class="btn-lg-green" />

        </form>
    </div>
</template>

<script>
export default {
  name: 'Auth',
  props: {
    errorMessage: String,
    busy: Boolean,
    brandRefer: Boolean,
    accessInvitaion: Object
  },
  data () {
    return {
      user: {
        email: '',
        password: '',
        remember: ''
      }
    }
  },
  methods: {
    onSubmit: function () {
      this.$emit('submit', this.user)
    }
  },
  watch: {
    accessInvitaion () {
      if (typeof this.accessInvitaion.email !== 'undefined') {
        this.user.email = this.accessInvitaion.email
      }
    }
  }
}
</script>

<style scoped>
h2 {
  font-size: 54px;
  line-height: 72px;
  margin-bottom: 20px;
  font-weight: 700;
  color: rgb(0, 0, 0);
  letter-spacing: 1px;
}

@media only screen and (max-width: 575px) {
  h2 {
    font-size: 35px;
    line-height: 45px;
  }
}
input:disabled {
  background: gainsboro;
}
</style>
