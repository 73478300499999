var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "sidebar-collapse": _vm.isSidebarCollapsed,
        "sidebar-open": !_vm.isSidebarCollapsed
      }
    },
    [
      _c("Sidebar", {
        attrs: { isSidebarCollapsed: _vm.isSidebarCollapsed },
        on: {
          sidebarCollapsed: function($event) {
            _vm.isSidebarCollapsed = true
          }
        }
      }),
      _c("div", { staticClass: "top-header" }, [
        _c("div", { staticClass: "container-head" }, [
          _c(
            "a",
            {
              on: {
                click: function($event) {
                  _vm.isSidebarCollapsed = !_vm.isSidebarCollapsed
                }
              }
            },
            [
              _c("img", {
                staticClass: "hamburger",
                attrs: { src: "/img/Dashboard/Navigation_icon.png" }
              })
            ]
          ),
          _vm.routeName == "sadmin-dashboard"
            ? _c("span", [_vm._v("\n                DASHBOARD\n            ")])
            : _vm._e(),
          _vm.routeName == "sadmin-brands"
            ? _c("span", [
                _vm._v("\n                BRANDS\n                "),
                _c(
                  "button",
                  {
                    staticClass: "header-action",
                    attrs: {
                      "data-toggle": "modal",
                      "data-target": "#brandpopup"
                    }
                  },
                  [_vm._v("NEW BRAND")]
                )
              ])
            : _vm._e(),
          _vm.routeName == "sadmin-brand-details"
            ? _c("span", [
                _vm._v("\n                BRANDS\n                "),
                _c(
                  "button",
                  {
                    staticClass: "header-action",
                    attrs: {
                      "data-toggle": "modal",
                      "data-target": "#brandpopup"
                    }
                  },
                  [_vm._v("NEW BRAND")]
                )
              ])
            : _vm._e(),
          _vm.routeName == "sadmin-store"
            ? _c("span", [
                _vm._v("\n                STORES\n                "),
                _c(
                  "button",
                  {
                    staticClass: "header-action",
                    attrs: {
                      "data-toggle": "modal",
                      "data-target": "#storepopup"
                    }
                  },
                  [_vm._v("STORE")]
                )
              ])
            : _vm._e(),
          _vm.routeName == "sadmin-store-location"
            ? _c("span", [
                _vm._v("\n                STORE LOCATIONS\n                "),
                _c(
                  "button",
                  {
                    staticClass: "header-action",
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/sadmin/cvs-stores")
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "hidden-xs" }, [
                      _vm._v("CVS STORES")
                    ])
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "header-action",
                    attrs: {
                      "data-toggle": "modal",
                      "data-target": "#storelocationpopup"
                    }
                  },
                  [_vm._v("STORE LOCATION")]
                )
              ])
            : _vm._e(),
          _vm.routeName == "sadmin-cities"
            ? _c("span", [
                _vm._v("\n                CITIES\n                "),
                _c(
                  "button",
                  {
                    staticClass: "header-action",
                    attrs: {
                      "data-toggle": "modal",
                      "data-target": "#add-city-popup"
                    }
                  },
                  [_vm._v("CITY")]
                )
              ])
            : _vm._e(),
          _vm.routeName == "sadmin-targeted-cities"
            ? _c("span", [
                _vm._v("\n                TARGETED CITIES\n                "),
                _c(
                  "button",
                  {
                    staticClass: "header-action",
                    attrs: {
                      "data-toggle": "modal",
                      "data-target": "#add-targeted-city-popup"
                    }
                  },
                  [_vm._v("TARGETED CITY")]
                )
              ])
            : _vm._e(),
          _vm.routeName == "sadmin-staff"
            ? _c("span", [
                _vm._v("\n                STAFF\n                "),
                _c(
                  "button",
                  {
                    staticClass: "header-action",
                    attrs: {
                      "data-toggle": "modal",
                      "data-target": "#staffpopup"
                    }
                  },
                  [_vm._v("STAFF")]
                )
              ])
            : _vm._e(),
          _vm.routeName == "sadmin-task-data-points"
            ? _c("span", [
                _vm._v("\n                TASK DATA POINTS\n                "),
                _c(
                  "button",
                  {
                    staticClass: "header-action",
                    attrs: {
                      "data-toggle": "modal",
                      "data-target": "#add-task-data-point-popup"
                    }
                  },
                  [_vm._v("TASK DATA POINT")]
                )
              ])
            : _vm._e(),
          _vm.routeName == "sadmin-countries"
            ? _c("span", [_vm._v("\n                Countries\n            ")])
            : _vm._e(),
          _vm.routeName == "sadmin-tasks"
            ? _c("span", [
                _vm._v("\n                Tasks\n                "),
                _c(
                  "button",
                  {
                    staticClass: "header-action",
                    attrs: {
                      "data-toggle": "modal",
                      "data-target": "#add-task-popup"
                    }
                  },
                  [_vm._v("TASK")]
                )
              ])
            : _vm._e(),
          _vm.routeName == "sadmin-brand-tutorial-videos"
            ? _c("span", [
                _vm._v(
                  "\n                Brand Tutorial Videos\n                "
                ),
                _c(
                  "button",
                  {
                    staticClass: "header-action",
                    attrs: {
                      "data-toggle": "modal",
                      "data-target": "#add-brand-tutorial-video-popup"
                    }
                  },
                  [_vm._v("Tutorial Video")]
                )
              ])
            : _vm._e(),
          _vm.routeName == "sadmin-picklrs"
            ? _c("span", [_vm._v("\n                PICKLRS\n            ")])
            : _vm._e(),
          _vm.routeName == "sadmin-user-referrals"
            ? _c("span", [
                _vm._v("\n                USER REFERRALS\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "sadmin-picklr"
            ? _c("span", [_vm._v("\n                PICKLR\n            ")])
            : _vm._e(),
          _vm.routeName == "pickls"
            ? _c("span", [_vm._v("\n                ALERTS\n            ")])
            : _vm._e(),
          _vm.routeName == "pickl-responses"
            ? _c("span", [
                _vm._v("\n                ALERT Responses\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "non-geo-pickls"
            ? _c("span", [
                _vm._v("\n                Go-Anywhere Alerts\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "sadmin-create-pickl"
            ? _c("span", [
                _vm._v("\n                Create Pickl For Brand\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "profile"
            ? _c("span", [
                _vm._v("\n                VIEW PROFILE\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "settings"
            ? _c("span", [_vm._v("\n                SETTINGS\n            ")])
            : _vm._e(),
          _vm.routeName == "packs"
            ? _c("span", [_vm._v("\n                PACKS\n            ")])
            : _vm._e(),
          _vm.routeName == "sadmin-heatmap"
            ? _c("span", [_vm._v("\n                HEAT MAP\n            ")])
            : _vm._e(),
          _vm.routeName == "sadmin-offers"
            ? _c("span", [
                _c(
                  "button",
                  {
                    staticClass: "header-action",
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/sadmin/offers/create")
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "hidden-xs" }, [
                      _vm._v("Create Offer")
                    ])
                  ]
                ),
                _vm._v("\n                Offers\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "sadmin-offers-create"
            ? _c("span", [
                _vm._v("\n                Create Offer\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "sadmin-offers-edit"
            ? _c("span", [_vm._v("\n                Edit Offer\n            ")])
            : _vm._e(),
          _vm.routeName == "sadmin-pickl-competitions"
            ? _c("span", [
                _vm._v(
                  "\n                Brand Pickl Competitors\n            "
                )
              ])
            : _vm._e(),
          _vm.routeName == "sadmin-survey-stats"
            ? _c("span", [
                _vm._v("\n                Insight Stats\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "sadmin-survey-edit"
            ? _c("span", [
                _vm._v("\n                Edit Insight\n            ")
              ])
            : _vm._e(),
          _vm.routeName == "sadmin-coupons"
            ? _c("span", [
                _c("span", [_vm._v("Coupons")]),
                _c(
                  "button",
                  {
                    staticClass: "header-action",
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/sadmin/coupons/create")
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "hidden-xs" }, [
                      _vm._v("Create Coupon")
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm.routeName == "sadmin-coupon-create"
            ? _c("span", [_c("span", [_vm._v("Create Coupon")])])
            : _vm._e(),
          _vm.routeName == "sadmin-coupon-edit"
            ? _c("span", [_c("span", [_vm._v("Edit Coupon")])])
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "below-header" }, [
        _c("div", { staticClass: "container-main" }, [
          _c(
            "div",
            { staticClass: "out-padd container-inner bg-pickl" },
            [
              _c("div", {
                staticClass: "container-overlay",
                on: {
                  click: function($event) {
                    _vm.isSidebarCollapsed = true
                  }
                }
              }),
              _c("router-view")
            ],
            1
          )
        ])
      ]),
      _c("BrandPopup", { attrs: { popupId: "brandpopup" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }