















































































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

@Component({
  components: {
    VuePerfectScrollbar
  }
})
export default class ExpiredPicklPopup extends Vue {
    @Prop() popupId!: string
    @Prop() brandId!: number

    public expiredPIckls: any = []
    public selectedPickls: any = []
    public selectAll: boolean = false
    public selectedPicklIds: any[] = []
    public daysCount: number= 30
    public loading: boolean = true

    mounted () {
      this.getExpiredPickls()
    }
    get creditsLeft () {
      let credits = this.$store.state.brandCreditsRemaining - this.selectedPickls.length
      return credits
    }
    getExpiredPickls () {
      this.expiredPIckls = []
      this.loading = true
      this.$store.dispatch('getBrandExpiredPickls', { brandId: this.brandId, days: this.daysCount }).then((response) => {
        this.expiredPIckls = response.body.data
        this.loading = false
      })
    }
    inSelectedStoresList (pickl: any) {
      let isObjectPresent = this.selectedPickls.find((o:any) => o.id === pickl.id)
      if (typeof isObjectPresent !== 'undefined') {
        return true
      } else {
        return false
      }
    }
    setSelected (pickl: any, selected: boolean) {
      let isObjectPresent = this.selectedPickls.findIndex((o:any) => o.id === pickl.id)
      if (isObjectPresent === -1) {
        if (this.creditsLeft === 0) {
          this.$notify({ text: 'You\'ve reached your maximum credits', type: 'error' })
          return false
        }
        this.selectedPickls.push({ id: pickl.id })
      } else {
        this.selectedPickls.splice(isObjectPresent, 1)
      }
    }
    onSelectAll (event: any) {
      if (this.selectAll) {
        if (this.creditsLeft < this.expiredPIckls.length) {
          this.$notify({ text: 'You\'ve reached your maximum credits', type: 'error' })
          return false
        }
        this.expiredPIckls.forEach((pickl: any) => {
          let isObjectPresent = this.selectedPickls.find((o:any) => o.id === pickl.id)
          if (typeof isObjectPresent !== 'undefined') {
            return false
          }
          let selectedRetailerIndex = this.selectedPickls.findIndex((o:any) => o.id === pickl.id)
          if (selectedRetailerIndex === -1) {
            this.selectedPickls.push({ id: pickl.id })
          }
        })
      } else {
        this.expiredPIckls.forEach((pickl: any) => {
          let selectedRetailerIndex = this.selectedPickls.findIndex((o:any) => o.id === pickl.id)
          if (selectedRetailerIndex !== -1) {
            this.removePickl(selectedRetailerIndex)
          }
        })
      }
    }

    reLaunchSelectedTasks () {
      this.selectedPickls.forEach((pickl: any) => {
        this.selectedPicklIds.push(pickl.id)
      })

      this.$store.dispatch('reLaunchExpiredTasks', { picklIds: this.selectedPicklIds, brandId: this.brandId }).then((response) => {
        let closeButton: HTMLElement = this.$refs.expiredPicklsPopupCloseButton as HTMLElement
        closeButton.click()
        this.$emit('tasksRelaunched')
        window.location.reload()
        this.$notify({ text: this.selectedPicklIds.length + ' pickl tasks has been relaunched successfully!', type: 'success' })
      })
    }
    removePickl (index: number) {
      this.selectedPickls.splice(index, 1)
    }
}
