import Vue from 'vue'
import Router from 'vue-router'

// routes
import sadminRoutes from './sadmin'
import { brandRoutes, brandSetupRoutes } from './brand'
import authRoutes from './auth'
import staffRoutes from './staff'

import PageNotFound from '@/components/common/PageNotFound.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    sadminRoutes,
    brandRoutes,
    brandSetupRoutes,
    staffRoutes,
    authRoutes,
    {
      path: '*',
      component: PageNotFound,
      meta: {
        title: 'Page Not Found'
      }
    }
  ]
})
