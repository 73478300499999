import Brand from '@/container/Brand.vue'
import FirstTimeLoginBrand from '@/container/FirstTimeLoginBrand.vue'

export const brandRoutes = {
  path: '/',
  component: Brand,
  redirect: '/brand/dashboard',
  children: [
    {
      path: '/brand/dashboard',
      name: 'brand-dashboard',
      component: () => import('@/views/Brand/Dashboard.vue'),
      meta: {
        name: 'brand-dashboard',
        requiresAuth: true,
        userType: 'brand',
        title: 'Brand Dashboard',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/reports',
      name: 'brand-reports',
      component: () => import('@/views/Brand/Reports.vue'),
      meta: {
        name: 'brand-reports',
        requiresAuth: true,
        userType: 'brand',
        title: 'Brand Reports',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/reports-new',
      name: 'brand-reports-new',
      component: () => import('@/views/Brand/ReportsNew.vue'),
      meta: {
        name: 'brand-reports-new',
        requiresAuth: true,
        userType: 'brand',
        title: 'Brand Reports - 2',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/subscription',
      name: 'brand-subscription',
      component: () => import('@/views/Brand/Subscription.vue'),
      meta: {
        name: 'brand-subscription',
        requiresAuth: true,
        userType: 'brand',
        title: 'Brand Subscription',
        breadcrumb: 'Brand Subscription'
      }
    },
    {
      path: '/brand/pickl-history',
      name: 'brand-pickl-history',
      component: () => import('@/views/Brand/PicklHistory.vue'),
      meta: {
        name: 'brand-pickl-history',
        requiresAuth: true,
        userType: 'brand',
        title: 'Brand PICKL History',
        breadcrumb: 'Brand PICKL History'
      }
    },
    {
      path: '/brand/insight-custom',
      name: 'brand-survey-create',
      component: () => import('@/views/SAdmin/InsightCustom.vue'),
      meta: {
        name: 'brand-insigh-custom',
        requiresAuth: true,
        userType: 'brand',
        title: 'Insight Custom',
        breadcrumb: 'Insight Custom'
      }
    },
    {
      path: '/brand/campaigns/:campaignId/pickls',
      name: 'brand-pickl-history',
      component: () => import('@/views/Brand/CampaignPicklHistory.vue'),
      meta: {
        name: 'brand-pickl-history',
        requiresAuth: true,
        userType: 'brand',
        title: 'Brand PICKL History',
        breadcrumb: 'Brand PICKL History'
      }
    },
    {
      path: '/brand/Campaigns-data',
      name: 'brand-campaigns-data',
      component: () => import('@/views/Brand/CampaignsData.vue'),
      meta: {
        name: 'brand-campaigns',
        requiresAuth: true,
        userType: 'brand',
        title: 'Brand Campaigns',
        breadcrumb: 'Brand Campaigns'
      }
    },
    {
      path: '/brand/Campaigns',
      name: 'brand-campaigns',
      component: () => import('@/views/Brand/Campaigns.vue'),
      meta: {
        name: 'brand-campaigns',
        requiresAuth: true,
        userType: 'brand',
        title: 'Brand Campaigns',
        breadcrumb: 'Brand Campaigns'
      }
    },
    {
      path: '/brand/non-geo-pickl-history',
      name: 'brand-non-geo-pickl-history',
      component: () => import('@/views/Brand/NonGeoPickls.vue'),
      meta: {
        name: 'brand-non-geo-pickl-history',
        requiresAuth: true,
        userType: 'brand',
        title: 'Brand Non Geo PICKL History',
        breadcrumb: 'Brand Non Geo PICKL History'
      }
    },
    {
      path: '/brand/pickls/:picklId',
      name: 'pickllist',
      component: () => import('@/views/SAdmin/PicklDetails.vue'),
      meta: {
        requiresAuth: true,
        userType: 'brand',
        name: 'pickls',
        title: 'Alerts',
        breadcrumb: 'Alerts'
      }
    },
    {
      path: '/brand/campaign-insights/:campaignId',
      name: 'brand-campaign-insights',
      component: () => import('@/views/Brand/CampaignInsights.vue'),
      meta: {
        requiresAuth: true,
        userType: 'brand',
        name: 'pickls',
        title: 'Alerts',
        breadcrumb: 'Alerts'
      }
    },
    {
      path: '/brand/pickl-responses/:picklId',
      name: 'picklDetails',
      component: () => import('@/views/SAdmin/PicklResponses.vue'),
      meta: {
        requiresAuth: true,
        userType: 'brand',
        name: 'pickl-responses',
        title: 'Alerts',
        breadcrumb: 'Alerts'
      }
    },
    {
      path: '/brand/non-geo-pickl/:picklId',
      name: 'pickllist',
      component: () => import('@/views/SAdmin/NonGeoPicklDetails.vue'),
      meta: {
        requiresAuth: true,
        userType: 'brand',
        name: 'pickls',
        title: 'Alerts',
        breadcrumb: 'Alerts'
      }
    },
    {
      path: '/brand/payment-history',
      name: 'brand-payment-history',
      component: () => import('@/views/Brand/PaymentHistory.vue'),
      meta: {
        name: 'brand-payment-history',
        requiresAuth: true,
        userType: 'brand',
        title: 'Brand Payment History',
        breadcrumb: 'Brand Payment History'
      }
    },
    {
      path: '/brand/products',
      name: 'brand-products',
      component: () => import('@/views/Brand/Products.vue'),
      meta: {
        name: 'brand-products',
        requiresAuth: true,
        userType: 'brand',
        title: 'Product Management',
        breadcrumb: 'Products Management'
      }
    },
    {
      path: '/brand/profile',
      name: 'brand-profile',
      component: () => import('@/views/Brand/Profile.vue'),
      meta: {
        name: 'brand-profile',
        requiresAuth: true,
        userType: 'brand',
        title: 'View Profile',
        breadcrumb: 'Profile'
      }
    },
    {
      path: '/brand/create-pickl',
      name: 'brand-create-pickl',
      component: () => import('@/views/Brand/CreatePickl.vue'),
      meta: {
        name: 'brand-create-pickl',
        requiresAuth: true,
        userType: 'brand',
        title: 'Create Pickl',
        breadcrumb: 'Create Pickl'
      }
    },
    {
      path: '/brand/create-non-geo-pickl',
      name: 'brand-create-non-geo-pickl',
      component: () => import('@/views/Brand/CreateNonGeoPickl.vue'),
      meta: {
        name: 'brand-create-non-geo-pickl',
        requiresAuth: true,
        userType: 'brand',
        title: 'Create Go-Anywhere Pickl',
        breadcrumb: 'Create Go-Anywhere Pickl'
      }
    },
    {
      path: '/brand/create-do-anywhere-pickl',
      name: 'sadmin-create-do-anywhere-pickl',
      component: () => import('@/views/Brand/CreateDoAnywherePickl.vue'),
      meta: {
        name: 'sadmin-create-do-anywhere-pickl',
        requiresAuth: true,
        userType: 'brand',
        title: 'Create Do Anywhere Pickl',
        breadcrumb: 'Create Do Anywhere Pickl'
      }
    },
    {
      path: '/brand/video',
      name: 'brand-video',
      component: () => import('@/components/Brand/BrandVideo.vue'),
      meta: {
        name: 'brand-video',
        requiresAuth: true,
        userType: 'brand',
        title: 'Video Explaining',
        breadcrumb: 'Video'
      }
    },
    {
      path: '/brand/details',
      name: 'brand-details',
      component: () => import('@/views/Brand/Details.vue'),
      meta: {
        name: 'brand-details',
        requiresAuth: true,
        userType: 'brand',
        title: 'Brand Details',
        breadcrumb: 'Brand Details'
      }
    },
    {
      path: '/brand/insights',
      name: 'brand-surveys',
      component: () => import('@/views/Brand/Surveys.vue'),
      meta: {
        name: 'brand-surveys',
        requiresAuth: true,
        userType: 'brand',
        title: 'Insights',
        breadcrumb: 'Insights'
      }
    },
    {
      path: '/brand/insight/create',
      name: 'brand-survey-create',
      component: () => import('@/views/Brand/CreateSurvey.vue'),
      meta: {
        name: 'brand-survey-create',
        requiresAuth: true,
        userType: 'brand',
        title: 'Create Insight',
        breadcrumb: 'Create Insight'
      }
    },
    {
      path: '/brand/insight/:surveyId/edit',
      name: 'brand-survey-edit',
      component: () => import('@/views/Brand/EditSurvey.vue'),
      meta: {
        name: 'brand-survey-edit',
        requiresAuth: true,
        userType: 'brand',
        title: 'Edit Insight',
        breadcrumb: 'Edit Insight'
      }
    },
    {
      path: '/brand/insight/:surveyId/stats',
      name: 'brand-survey-stats',
      component: () => import('@/views/Brand/SurveyStats.vue'),
      meta: {
        name: 'brand-survey-stats',
        requiresAuth: true,
        userType: 'brand',
        title: 'Insight Stats',
        breadcrumb: 'Insight Stats'
      }
    },
    {
      path: '/brand/offers',
      name: 'brand-offers',
      component: () => import('@/views/Brand/Offers.vue'),
      meta: {
        name: 'brand-offers',
        requiresAuth: true,
        userType: 'brand',
        title: 'Offers',
        breadcrumb: 'Offers'
      }
    },
    {
      path: '/brand/offers/fetch',
      name: 'brand-offers-details',
      component: () => import('@/views/Brand/FetchOffer.vue'),
      meta: {
        name: 'brand-offers-details',
        requiresAuth: true,
        userType: 'brand',
        title: 'Fetch Offer',
        breadcrumb: 'Offers'
      }
    },
    {
      path: '/brand/location-catalogues',
      name: 'brand-location-catalouges',
      component: () => import('@/views/Brand/LocationCatalogues.vue'),
      meta: {
        name: 'brand-location-catalogues',
        requiresAuth: true,
        userType: 'brand',
        title: 'Store Clusters',
        breadcrumb: 'Store Clusters'
      }
    },
    {
      path: '/brand/media',
      name: 'brand-media',
      component: () => import('@/views/Brand/Media.vue'),
      meta: {
        name: 'brand-media',
        requiresAuth: true,
        userType: 'brand',
        title: 'Media',
        breadcrumb: 'Media'
      }
    },

    {
      path: '/brand/coupons',
      name: 'brand-coupons',
      component: () => import('@/views/Brand/Coupons.vue'),
      meta: {
        name: 'brand-coupons',
        requiresAuth: true,
        userType: 'brand',
        title: 'Coupons',
        breadcrumb: 'Coupons'
      }
    },
    {
      path: '/brand/coupons/create',
      name: 'brand-coupon-create',
      component: () => import('@/views/Brand/CreateCoupon.vue'),
      meta: {
        name: 'brand-coupon-create',
        requiresAuth: true,
        userType: 'brand',
        title: 'Create Coupon',
        breadcrumb: 'Create Coupon'
      }
    },
    {
      path: '/brand/coupons/:couponId/edit',
      name: 'brand-coupon-edit',
      component: () => import('@/views/Brand/EditCoupon.vue'),
      meta: {
        name: 'brand-coupon-edit',
        requiresAuth: true,
        userType: 'brand',
        title: 'Edit Coupon',
        breadcrumb: 'Edit Coupon'
      }
    },
    {
      path: '/brand/location-catalogues/create',
      name: 'brand-create-locaton-catalogue',
      component: () => import('@/views/Brand/CreateLocationCatalogue.vue'),
      meta: {
        name: 'create-location-catalogue',
        requiresAuth: true,
        userType: 'brand',
        title: 'Create Store Clusters',
        breadcrumb: 'Create Store Clusters'
      }
    },
    {
      path: '/brand/location-catalogues/:locationCatalogueId/edit',
      name: 'brand-edit-location-catalogue',
      component: () => import('@/views/Brand/EditLocationCatalogue.vue'),
      meta: {
        name: 'edit-location-catalogue',
        requiresAuth: true,
        userType: 'brand',
        title: 'Edit Store Clusters',
        breadcrumb: 'Edit Store Clusters'
      }
    },
    {
      path: '/brand/create-brand',
      name: 'brand-create-brand',
      component: () => import('@/views/Brand/EditLocationCatalogue.vue'),
      meta: {
        name: 'brand-create-brand',
        requiresAuth: true,
        userType: 'brand',
        title: 'Create Brand',
        breadcrumb: 'Create Brand'
      }
    },
    {
      path: '/brand/tutorial-videos',
      name: 'brand-tutorials',
      component: () => import('@/views/Brand/TutorialVideos.vue'),
      meta: {
        name: 'brand-tutorial-videos',
        requiresAuth: true,
        userType: 'brand',
        title: 'Video Tutorials',
        breadcrumb: 'Video Tutorials'
      }
    }
  ]
}

export const brandSetupRoutes = {
  path: '/',
  component: FirstTimeLoginBrand,
  children: [{
    path: '/brand/onboarding',
    name: 'brand-welcome',
    component: () => import('@/views/Brand/VideoDetails.vue'),
    meta: {
      name: 'brand-welcome',
      requiresAuth: true,
      userType: 'brand',
      title: 'Video Explaining',
      breadcrumb: 'Video'
    }
  },
  {
    path: '/brand/onboarding/subscription',
    name: 'brand-select-subscription',
    component: () => import('@/views/Brand/FirstSubscription.vue'),
    meta: {
      name: 'brand-select-subscription',
      requiresAuth: true,
      userType: 'brand',
      title: 'First Subscription',
      breadcrumb: 'Subscription'
    }
  },
  {
    path: '/brand/data-access-subscription',
    name: 'data-access-subscription',
    component: () => import('@/views/Brand/DataAccessSubscriptionCheckPage.vue'),
    meta: {
      name: 'data-access-subscription',
      requiresAuth: true,
      userType: 'brand',
      title: 'Data Access Subscription',
      breadcrumb: 'Subscription'
    }
  }
  ]
}
