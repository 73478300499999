var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { "sidebar-collapse": _vm.isSidebarCollapsed } }, [
    _c("div", { staticClass: "top-header" }, [
      _c(
        "div",
        { staticClass: "sidebar text-center" },
        [
          _c(
            "router-link",
            {
              attrs: { to: "/staff/dashboard" },
              nativeOn: {
                click: function($event) {
                  return _vm.$emit("sidebarCollapsed")
                }
              }
            },
            [
              _c("img", {
                staticClass: "sidebar-logo",
                attrs: { src: "/img/Dashboard/HeaderLogo.png" }
              })
            ]
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "below-header" }, [
      _c("div", { staticClass: "sidebar" }, [
        _c("div", { staticClass: "sidebar-inner sidebar-mobile" }, [
          _vm.$store.state.userData
            ? _c("div", { staticClass: "sidebar-profile" }, [
                _c("img", {
                  staticClass: "profile-photo-sidebar",
                  attrs: {
                    src:
                      _vm.$store.state.userData.image + "?fit=crop&w=150&h=150"
                  },
                  on: {
                    error: function($event) {
                      return _vm.$common.getDefaultImage("user")
                    }
                  }
                }),
                _c(
                  "div",
                  { staticClass: "profile-details" },
                  [
                    _c("h4", [_vm._v(_vm._s(_vm.$store.state.userData.name))]),
                    _c(
                      "router-link",
                      {
                        staticClass: "grey-link",
                        attrs: { to: "/staff/profile" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.$emit("sidebarCollapsed")
                          }
                        }
                      },
                      [_vm._v("View Profile")]
                    ),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: { click: _vm.logout }
                      },
                      [_vm._v("Log Out")]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "clear" })
              ])
            : _vm._e(),
          _c("ul", [
            _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "alerts-cls",
                    attrs: { to: "/staff/pickls" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.$emit("sidebarCollapsed")
                      }
                    }
                  },
                  [_vm._v("Geo-Located")]
                )
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "non-geo-pickl-cls",
                    attrs: { to: "/staff/non-geo-pickls" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.$emit("sidebarCollapsed")
                      }
                    }
                  },
                  [_vm._v("Go-Anywhere")]
                )
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "picklrs-cls",
                    attrs: { to: "/staff/picklrs" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.$emit("sidebarCollapsed")
                      }
                    }
                  },
                  [_vm._v("Picklr")]
                )
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "stores-cls",
                    attrs: { to: "/staff/stores" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.$emit("sidebarCollapsed")
                      }
                    }
                  },
                  [_vm._v("Store")]
                )
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    attrs: { to: "/staff/store_locations" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.$emit("sidebarCollapsed")
                      }
                    }
                  },
                  [_vm._v("Store Location")]
                )
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "brands-cls",
                    attrs: { to: "/staff/brands" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.$emit("sidebarCollapsed")
                      }
                    }
                  },
                  [_vm._v("Brand")]
                )
              ],
              1
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "sidebar-desktop" },
          [
            _c("VuePerfectScrollbar", { staticClass: "scroll-height" }, [
              _c("div", { staticClass: "sidebar-inner" }, [
                _vm.$store.state.userData
                  ? _c("div", { staticClass: "sidebar-profile" }, [
                      _c("img", {
                        staticClass: "profile-photo-sidebar",
                        attrs: {
                          src:
                            _vm.$store.state.userData.image ||
                            _vm.$common.getDefaultImage("user")
                        },
                        on: {
                          error: function($event) {
                            return _vm.$common.getDefaultImage("user")
                          }
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "profile-details" },
                        [
                          _c("h4", [
                            _vm._v(_vm._s(_vm.$store.state.userData.name))
                          ]),
                          _c(
                            "router-link",
                            {
                              staticClass: "grey-link",
                              attrs: { to: "/staff/profile" }
                            },
                            [_vm._v("View Profile")]
                          ),
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:;" },
                              on: { click: _vm.logout }
                            },
                            [_vm._v("Log Out")]
                          )
                        ],
                        1
                      ),
                      _c("div", { staticClass: "clear" })
                    ])
                  : _vm._e(),
                _c("ul", [
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "alerts-cls",
                          attrs: { to: "/staff/pickls" }
                        },
                        [_vm._v("Geo-Located")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "non-geo-pickl-cls",
                          attrs: { to: "/staff/non-geo-pickls" }
                        },
                        [_vm._v("Go-Anywhere")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "picklrs-cls",
                          attrs: { to: "/staff/picklrs" }
                        },
                        [_vm._v("Picklr")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "brands-cls",
                          attrs: { to: "/staff/brands" }
                        },
                        [_vm._v("Brand")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }