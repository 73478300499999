<template>
    <div class="carousel-videos">
        <div id="carousel-videos" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner" role="listbox">
                <div class="item active">
                    <div class="frame">
                        <img class="img-responsive center-block" src="/img/brand_new_feature.png" />
                    </div>
                </div>
                <div class="item">
                    <div class="frame">
                        <img class="img-responsive center-block" src="/img/slider_img_3.png" />
                    </div>
                </div>
            </div>
            <!-- <a class="left carousel-control" href="#carousel-default" role="button" data-slide="prev">
                <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="right carousel-control" href="#carousel-default" role="button" data-slide="next">
                <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a> -->
            <ol class="carousel-indicators">
                <li data-target="#carousel-videos" data-slide-to="0" class="active"><div class="dot"></div></li>
                <li data-target="#carousel-videos" data-slide-to="1"><div class="dot"></div></li>
            </ol>
        </div>
    </div>
</template>

<script>
export default {
  name: 'TutorialVideos'
}
</script>

<style lang="scss" scoped>
.carousel-videos {
    position: relative;
}

.carousel-videos .carousel-indicators {
    bottom: -60px;
}

.carousel-videos .carousel-indicators li {
    width: 29px;
    height: 29px;
    padding: 8px;
    border: none;
    margin: 0;
}

.carousel-videos .carousel-indicators li .dot {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #12dc6d;
}

.carousel-videos .carousel-indicators .active .dot {
    background: #703053;
}

.carousel-videos .carousel {
    margin-bottom: 60px;
    text-align: center;
}

.carousel-videos .carousel-inner .item {
    // height: 325px;
    width: 100%;
    max-width: 660px;
    margin: 0 auto;
    overflow: hidden;
    padding: 0 25px;
}

.carousel-videos .carousel-inner .item:nth-child(2) a {
    cursor: pointer;
}

.carousel-videos .carousel-inner .item:nth-child(2) .thumb {
    background-color: #782b54;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.frame {
    padding: 1px;
    border: 2px solid #782b54;
    // background-color: #782b54;
    border-radius: 3px;
    overflow: hidden;
}

.carousel-videos .carousel-inner .item:nth-child(2) .playbutton {
    width: 80px;
    height: 80px;
    position: absolute;
    left: calc(50% - 23px);
    background: #12db6d;
    border-radius: 50px;
}

p {
    font-size: 15px;
    color: black;
    padding: 0 10px;
}

h2 {
  color: #782b45;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 15px;
}

.starting {
    width: 50%;
    max-width: 649px;
    min-width: 200px;
    margin-bottom: -10px;
    margin-top: 20px;
}

.pack {
  margin-top: 0;
  padding: 0 15px;
  overflow: hidden;
}

// .pack-left,
// .pack-right {
//   width: 35%;
//   display: inline-block;
//   line-height: 288px;
//   vertical-align: text-bottom;
//   margin-bottom: 35px;
// }

.pickl-pack {
  width: 30%;
  display: inline-block;
}

.emoticon {
  height: 24px;
}

.team-photo {
  border-radius: 50%;
  border: 2px #12db6d solid;
  position: relative;
  margin: 0 auto;
  margin-bottom: 20px;
}

.team-photo img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
  overflow: hidden;
}

.team-photo a {
  position: absolute;
  bottom: -14px;
  left: calc(50% - 14px);
  width: 28px;
  height: 28px;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  background-color: #0075b8;
  padding: 5px;
}

#about strong {
  font-weight: 500;
  font-size: 0.94em;
  width: 100%;
  display: inline-block;
  text-align: center;
}

#about p {
  margin: 0;
}

#about h3 {
  margin-top: 3%;
  margin-bottom: 0;
  color: #782b54;
}

#about .single {
  margin-bottom: 13px;
}

#about .team-col {
    padding: 0 15px;
}

#about h2 {
    margin-bottom: 12px;
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
    #about strong {
        font-size: 0.85em;
    }

    #about .team-col {
        padding: 0 7px;
    }
}

@media only screen and (max-width: 991px) {
    .carousel-videos .carousel-inner .item {
        padding: 0 15px;
        height: auto;
    }

    .scroll-height .vertical-center-transform {
        position: static;
        transform: none;
    }
}

@media only screen and (max-width: 767px) {
  .team-photo {
      margin-bottom: 15px;
  }

  #about strong {
      margin-bottom: 1.5em;
  }
}

@media only screen and (max-width: 575px) {
    p {
        font-size: 14px;
    }

    .pack {
      margin: 1em -15px 0;
    }

    // .pack-left {
    //   width: 60%;
    //   margin-left: -35%;
    //   height: auto;
    // }

    .pickl-pack {
      width: 50%;
      height: auto;
    }

    // .pack-right {
    //   width: 60%;
    //   margin-right: -35%;
    //   height: auto;
    // }
}

@media only screen and (max-width: 353px) {
    #about strong {
        font-size: 0.85em;
    }
}
</style>
