import SAdmin from '@/container/Sadmin.vue'

export default {
  path: '/',
  component: SAdmin,
  redirect: '/sadmin/dashboard',
  children: [
    {
      path: '/sadmin/dashboard',
      name: 'sadmin-dashboard',
      component: () => import('@/views/SAdmin/Dashboard.vue'),
      meta: {
        name: 'sadmin-dashboard',
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Super Admin Dashboard',
        breadcrumb: 'Login',
        sidebar: false
      }
    },
    {
      path: '/sadmin/store-locations',
      name: 'sadmin-store-locations',
      component: () => import('@/views/SAdmin/StoreLocations.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Stores Locations | Super Admin',
        breadcrumb: 'Stores',
        name: 'sadmin-store-location'
      }
    },
    {
      path: '/sadmin/cvs-stores',
      name: 'cvs-stores',
      component: () => import('@/views/SAdmin/CvsStores.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Cvs Stores | Super Admin',
        breadcrumb: 'Cvs Stores',
        name: 'sadmin-cvs-stores'
      }
    },
    {
      path: '/sadmin/cities',
      name: 'sadmin-cities',
      component: () => import('@/views/SAdmin/Cities.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Cities',
        breadcrumb: 'cities',
        name: 'sadmin-cities'
      }
    },
    {
      path: '/sadmin/targeted-cities',
      name: 'sadmin-targeted-cities',
      component: () => import('@/views/SAdmin/TargetedCities.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Targeted Cities',
        breadcrumb: 'targeted-cities',
        name: 'sadmin-targeted-cities'
      }
    },
    {
      path: '/sadmin/stores',
      name: 'sadmin-stores',
      component: () => import('@/views/SAdmin/Stores.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Stores | Super Admin',
        breadcrumb: 'Stores',
        name: 'sadmin-store'
      }
    },
    {
      path: '/sadmin/picklrs',
      name: 'sadmin-picklrlist',
      component: () => import('@/views/SAdmin/Picklrs.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'sadmin-picklrs',
        title: 'Picklr',
        breadcrumb: 'Picklrs'
      }
    },
    {
      path: '/sadmin/picklrs/:picklrId',
      name: 'sadmin-picklrdetails',
      component: () => import('@/views/SAdmin/PicklrDetails.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'sadmin-picklr',
        title: 'Picklr',
        breadcrumb: 'Details'
      }
    },
    {
      path: '/sadmin/brands',
      name: 'sadmin-brandlist',
      component: () => import('@/views/SAdmin/Brand.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'sadmin-brands',
        title: 'Brand',
        breadcrumb: 'Brands'
      }
    },
    {
      path: '/sadmin/brands/:id',
      name: 'branddetails',
      component: () => import('@/views/SAdmin/BrandDetails.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'sadmin-brand-details',
        title: 'Brand',
        breadcrumb: 'Brand Details'
      }
    },
    {
      path: '/sadmin/brands/:brandId/create-pickl',
      name: 'sadmin-create-pickl',
      component: () => import('@/views/SAdmin/CreatePickl.vue'),
      meta: {
        name: 'sadmin-create-pickl',
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Create Pickl',
        breadcrumb: 'Create Pickl'
      }
    },
    {
      path: '/sadmin/brands/:brandId/data-visuals',
      name: 'sadmin-brand-data-visuals',
      component: () => import('@/views/SAdmin/BrandReports.vue'),
      meta: {
        name: 'sadmin-brand-data-visuals',
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Data Visuals',
        breadcrumb: 'Data Visuals'
      }
    },
    {
      path: '/sadmin/brands/:brandId/create-non-geo-pickl',
      name: 'sadmin-create-non-geo-pickl',
      component: () => import('@/views/SAdmin/CreateNonGeoPickl.vue'),
      meta: {
        name: 'sadmin-create-non-geo-pickl',
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Create Pickl',
        breadcrumb: 'Create Pickl'
      }
    },
    {
      path: '/sadmin/brands/:brandId/create-do-anywhere-pickl',
      name: 'sadmin-create-do-anywhere-pickl',
      component: () => import('@/views/SAdmin/CreateDoAnywherePickl.vue'),
      meta: {
        name: 'sadmin-create-do-anywhere-pickl',
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Create Do Anywhere Pickl',
        breadcrumb: 'Create Do Anywhere Pickl'
      }
    },
    {
      path: '/sadmin/pickls',
      name: 'sadmin-pickllist',
      component: () => import('@/views/SAdmin/Pickls.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'pickls',
        title: 'Alerts',
        breadcrumb: 'Alerts'
      }
    },
    {
      path: '/sadmin/campaigns/:campaignId/pickls',
      name: 'sadmin-pickllist',
      component: () => import('@/views/SAdmin/CampaignPickls.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'pickls',
        title: 'Alerts',
        breadcrumb: 'Alerts'
      }
    },
    {
      path: '/sadmin/non-geo-pickls',
      name: 'sadmin-non-geo-pickllist',
      component: () => import('@/views/SAdmin/NonGeoPickls.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'non-geo-pickls',
        title: 'Non Geo Alerts',
        breadcrumb: 'Non Geo Alerts'
      }
    },
    {
      path: '/sadmin/pickls/:picklId',
      name: 'picklDetails',
      component: () => import('@/views/SAdmin/PicklDetails.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'pickls',
        title: 'Alerts',
        breadcrumb: 'Alerts'
      }
    },
    {
      path: '/sadmin/pickl-responses/:picklId',
      name: 'picklDetails',
      component: () => import('@/views/SAdmin/PicklResponses.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'pickl-responses',
        title: 'Alerts',
        breadcrumb: 'Alerts'
      }
    },
    {
      path: '/sadmin/non-geo-pickl/:picklId',
      name: 'picklStats',
      component: () => import('@/views/SAdmin/NonGeoPicklDetails.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'Go-Anywhere Pickl Stats',
        title: 'Go-Anywhere Pickl Stats',
        breadcrumb: 'Go-Anywhere Pickl Stats'
      }
    },
    {
      path: '/sadmin/Campaigns',
      name: 'sadmin-campaigns',
      component: () => import('@/views/SAdmin/Campaigns.vue'),
      meta: {
        name: 'sadmin-campaigns',
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Campaigns',
        breadcrumb: 'Campaigns'
      }
    },
    {
      path: '/sadmin/profile',
      name: 'sadminprofile',
      component: () => import('@/views/SAdmin/Profile.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'profile',
        title: 'View Profile',
        breadcrumb: 'View Profile'
      }
    },
    {
      path: '/sadmin/settings',
      name: 'settings',
      component: () => import('@/views/SAdmin/Settings.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'settings',
        title: 'Settings',
        breadcrumb: 'Settings'
      }
    },
    {
      path: '/sadmin/packs',
      name: 'packs',
      component: () => import('@/views/SAdmin/Packs.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'packs',
        title: 'Packs',
        breadcrumb: 'Packs'
      }
    },
    {
      path: '/sadmin/brand-subscription-history',
      name: 'brand-subscription-history',
      component: () => import('@/views/SAdmin/BrandSubscriptionHistory.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'BrandSubscriptionHistory',
        title: 'Brand Subscription History',
        breadcrumb: 'Brand Subscription History'
      }
    },
    {
      path: '/sadmin/staff',
      name: 'stafflist',
      component: () => import('@/views/SAdmin/Staff.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'sadmin-staff',
        title: 'Staff',
        breadcrumb: 'Staff'
      }
    },
    {
      path: '/sadmin/heatmap',
      name: 'picklrheatmap',
      component: () => import('@/views/SAdmin/HeatMap.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'picklr-heat-map',
        title: 'Picklr Heat Map',
        breadcrumb: 'Picklr Heat Map'
      }
    },
    {
      path: '/sadmin/countries',
      name: 'countries',
      component: () => import('@/views/SAdmin/Countries.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'sadmin-countries',
        title: 'Countries',
        breadcrumb: 'Countries'
      }
    },
    {
      path: '/sadmin/tasks',
      name: 'tasks',
      component: () => import('@/views/SAdmin/Tasks.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'sadmin-tasks',
        title: 'Tasks',
        breadcrumb: 'Tasks'
      }
    },
    {
      path: '/sadmin/offers',
      name: 'offers',
      component: () => import('@/views/SAdmin/Offers.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'sadmin-offers',
        title: 'Offers',
        breadcrumb: 'Offers'
      }
    },
    {
      path: '/sadmin/offers/create',
      name: 'create-offer',
      component: () => import('@/views/SAdmin/CreateOffer.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'sadmin-offers-create',
        title: 'Create Offer',
        breadcrumb: 'Offers'
      }
    },
    {
      path: '/sadmin/offers/:offerId/edit',
      name: 'edit-offer',
      component: () => import('@/views/SAdmin/EditOffer.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'sadmin-offers-edit',
        title: 'Edit Offer',
        breadcrumb: 'Offers'
      }
    },
    {
      path: '/sadmin/pickls/:picklId/tasks/:taskId/competitions',
      name: 'sadmin-brand-competitions',
      component: () => import('@/views/SAdmin/PicklCompetitions.vue'),
      meta: {
        requiresAuth: true,
        userType: 'super-admin',
        name: 'sadmin-pickl-competitions',
        title: 'Brand Pickl Competitions',
        breadcrumb: 'Brand Pickl Competitions'
      }
    },
    {
      path: '/sadmin/brands/:brandId/surveys/create',
      name: 'sadmin-survey-create',
      component: () => import('@/views/Brand/CreateSurvey.vue'),
      meta: {
        name: 'sadmin-survey-create',
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Create Survey',
        breadcrumb: 'Create Survey'
      }
    },
    {
      path: '/sadmin/brands/:brandId/insight-custom',
      name: 'sadmin-survey-create',
      component: () => import('@/views/SAdmin/InsightCustom.vue'),
      meta: {
        name: 'sadmin-brand-insigh-custom',
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Insight Custom',
        breadcrumb: 'Insight Custom'
      }
    },
    {
      path: '/sadmin/brands/:brandId/surveys/:surveyId/edit',
      name: 'sadmin-survey-edit',
      component: () => import('@/views/Brand/EditSurvey.vue'),
      meta: {
        name: 'sadmin-survey-edit',
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Edit Survey',
        breadcrumb: 'Edit Survey'
      }
    },
    {
      path: '/sadmin/brands/:brandId/surveys/:surveyId/stats',
      name: 'sadmin-survey-stats',
      component: () => import('@/views/Brand/SurveyStats.vue'),
      meta: {
        name: 'sadmin-survey-stats',
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Survey Stats',
        breadcrumb: 'Survey Stats'
      }
    },
    {
      path: '/sadmin/pop-tasks',
      name: 'sadmin-pop-tasks',
      component: () => import('@/views/SAdmin/PopTasks.vue'),
      meta: {
        name: 'sadmin-pop-tasks',
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Pop Tasks',
        breadcrumb: 'Pop Tasks'
      }
    },
    {
      path: '/sadmin/task-data-points',
      name: 'task-data-points',
      component: () => import('@/views/SAdmin/TaskDataPoints.vue'),
      meta: {
        name: 'sadmin-task-data-points',
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Task Data Points',
        breadcrumb: 'Task Data Points'
      }
    },
    {
      path: '/sadmin/brand-tutorial-videos',
      name: 'brand-tutorial-videos',
      component: () => import('@/views/SAdmin/BrandTutorialVideos.vue'),
      meta: {
        name: 'sadmin-brand-tutorial-videos',
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Brand Tutorial Videos',
        breadcrumb: 'Brand Tutorial Videos'
      }
    },
    {
      path: '/sadmin/user-referrals',
      name: 'sadmin-user-referrals',
      component: () => import('@/views/SAdmin/UserReferrals.vue'),
      meta: {
        name: 'sadmin-user-referrals',
        requiresAuth: true,
        userType: 'super-admin',
        title: 'User Referrals',
        breadcrumb: 'User Referrals'
      }
    },

    {
      path: '/sadmin/coupons',
      name: 'sadmin-coupons',
      component: () => import('@/views/SAdmin/Coupons.vue'),
      meta: {
        name: 'sadmin-coupons',
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Coupons',
        breadcrumb: 'Coupons'
      }
    },
    {
      path: '/sadmin/coupons/create',
      name: 'sadmin-coupon-create',
      component: () => import('@/views/SAdmin/CreateCoupon.vue'),
      meta: {
        name: 'sadmin-coupon-create',
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Create Coupon',
        breadcrumb: 'Create Coupon'
      }
    },
    {
      path: '/sadmin/coupons/:couponId/edit',
      name: 'sadmin-coupon-edit',
      component: () => import('@/views/SAdmin/EditCoupon.vue'),
      meta: {
        name: 'sadmin-coupon-edit',
        requiresAuth: true,
        userType: 'super-admin',
        title: 'Edit Coupon',
        breadcrumb: 'Edit Coupon'
      }
    }
  ]
}
