<template>
    <div class="outer-box vertical-center-transform">
        <div class="posRel">
          <div class="col-xs-12 col-md-6">
              <div class="col-xs-12 login-box">
                  <div class="col-xs-12 text-center">
                      <img class="pickl-logo" src="/img/pickl-logo.png" />
                  </div>
                  <Auth :busy="busy" v-if="!accountVerified"  @submit="authenticateUser" :errorMessage="errorMessage" />
                  <TwoStep :busy="busy" v-else @submit="loginUser" @authenticateUserAgain="authenticateUser" :user="user"  :errorMessage="errorMessage"/>
                  <router-link to="/reset/password" style="float:left;">Forgot Password?</router-link><br/><br/>

              </div>
          </div>
          <div class="col-xs-12 col-md-6 video-out vertical-center-transform">
              <div class="video-box">
                  <!-- <h3>Manage Pickl, Picklrs Easily</h3> -->
                  <TutorialVideos></TutorialVideos>
              </div>
          </div>
          <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
import TutorialVideos from '@/components/common/TutorialVideos.vue'
import Auth from '@/components/common/Auth.vue'
import TwoStep from '@/components/SAdmin/TwoStep.vue'

export default {
  name: 'login',
  components: {
    TutorialVideos,
    Auth,
    TwoStep
  },
  data: function () {
    return {
      busy: false,
      accountVerified: false,
      errorMessage: '',
      user: {}
    }
  },
  methods: {
    authenticateUser (user) {
      this.busy = true
      this.errorMessage = ''
      this.$http
        .post('verify-account', user, {
          headers: {
            'user-type': 'sadmin'
          }
        })
        .then(
          function (response) {
            this.accountVerified = true
            this.user = user
            this.$notify({ type: 'success', text: 'Verification code has been send to your registered number!' })
          },
          function (response) {
            this.accountVerified = false
            this.errorMessage = response.body.message
          }
        ).finally(() => {
          this.busy = false
        })
    },
    loginUser (code) {
      this.errorMessage = ''
      this.user.code = code
      this.$http
        .post('sadmin-login', this.user, {
          headers: {
            'user-type': 'sadmin'
          }
        })
        .then(
          function (response) {
            this.$store.commit('setTokenToLocalStorage', response.body)
            this.getUserByToken()
          },
          function (response) {
            this.errorMessage = response.body.message
            this.busy = false
          }
        )
    },
    getUserByToken () {
      // this.$http
      //   .get('token-user', {
      //   })
      this.$store.dispatch('getUserData', 'super-admin')
        .then(
          (response) => {
            this.$store.commit('setUserDataToLocalStorage', response.body)
            this.$router.push('/sadmin/dashboard')
            this.busy = false
          },
          (response) => {
            this.errorMessage = response.body.message
            this.busy = false
          }
        )
    }
  }
}
</script>

<style scoped>
.outer-box {
  margin: 0 auto;
  max-width: 1366px;
  width: 100%;
  background-image: url("/img/background-portrait.png");
  background-size: 50% 100%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.pickl-logo {
  margin-bottom: 30px;
  width: 120px;
  height: 120px;
  border-radius: 7px;
}

.login-box {
  margin: 5% auto 0;
  padding: 10px 90px 20px;
}

.video-out {
  padding-left: 0;
    left: auto;
    right: 0;
}

.video-box {
  margin: 0 auto;
  /* margin: 0 0 20px; */
  padding: 25px 0 15px;
}

.video-box h3 {
  font-weight: 700;
  text-align: center;
  font-size: 44px;
  color: black;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .login-box {
    padding: 10px 35px 20px;
  }
}

@media only screen and (max-width: 991px) {
  .outer-box {
    background-size: 100% 100vh;
        background-repeat: repeat-y;
        position: static;
        transform: none;
  }

  .video-out {
    padding-left: 15px;
        min-height: auto;
        position: static;
        transform: none;
    }

    .video-box {
        margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .login-box {
    padding: 15px;
  }

  .video-box h3 {
    font-size: 36px;
        padding: 15px;
    }
}

@media only screen and (max-height: 660px) {
    .outer-box {
        position: static;
        transform: none;
  }
}
</style>
