









































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class SwitchBrandPopup extends Vue {
  @Prop() popupId!: string

  public brandId: any = ''
  public userBrands: any[] = []

  mounted () {
    this.getUserBrandsList()
  }

  getUserBrandsList () {
    this.$store.dispatch('getUserBrands', this.$store.state.userData.id)
      .then(
        (response: any) => {
          console.log(response.body.data)
          this.userBrands.push(...response.body.userBrands)
        }
      )
  }

  switchUserBrand () {
    this.$validator.validateAll('switch-user-brand').then((result) => {
      if (result) {
        this.$store.dispatch('switchUserBrandDashboard', { userId: this.$store.state.userData.id, brandId: this.brandId })
          .then(
            (response: any) => {
              this.$store.commit('setUserDataToLocalStorage', response.body)
              this.$notify({ type: 'success', text: 'Brand Changed Successfully!' })
              let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
              popupCloseButton.click()
              window.location.reload()
            },
            (error: any) => {
              if (error.body.message !== null) {
                this.$notify({ type: 'error', text: error.body.message })
              }
            }
          )
      }
    })
  }
}
